import { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bingSettings } from "./BingSettings/bingSettings";
import { googleSettings } from "./GoogleSettings/googleSettings";
import { SearchContext } from "../../../context/SearchContext";
import { useSnackbar } from "../../../context/Snackbar";
import { isMobile } from "react-device-detect";
import { ResultsContext } from "../../../context/ResultsContext";
import { saveQuery } from "../../../store/search";
import { search } from "../../../store/result";
import QueryParam from "../QueryParam";
import Parameter from "../Parameter";
import MobileSearchBar from "./MobileSearchBar";
import clearText from "../../../assets/images/clear.png";
import searchIcon from "../../../assets/images/search.png";
import saveIcon from "../../../assets/icons/save.png"
import arrowForward from "../../../assets/images/arrow-forward-2.png"
import { Tooltip } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const settings = { Google: googleSettings, Bing: bingSettings };

const isProduction = process.env.NODE_ENV === "production";

export default function SearchBar({ status, setStatus }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isCurrentSearch = window.location.pathname === '/search/current'

    const [gptSearch, setGptSearch] = useState(false);
    const [padding, setPadding] = useState('5')

    const { setPageNum, setTotalPages } = useContext(ResultsContext);
    const { openSnackbar } = useSnackbar()

    const {
        setSearch,
        setLoadingResults,
        showOptions,
        setShowOptions,
        currCharCount,
        maxCharCount,
        searchState,
        clickHistory,
    } = useContext(SearchContext);
    const {
        query,
        string,
        engine,
        language,
        country,
        engineType,
        updateQuery,
        setString
    } = searchState

    useEffect(() => {
        if (showOptions) setPadding('5')
        else setPadding('0')
    }, [showOptions])

    useEffect(() => {
        const path = window.location.pathname === '/search'
        if (path) {
            setShowOptions(true)
        }
    }, [])

    const handleSaveQuery = () => {
        const options = {
            q: query.join(";"),
            hl: language,
            engine: engine.toLowerCase(),
            start: 0,
            string: string,
        };
        if (engine === "Bing") {
            options.location = country;
        } else {
            options.cr = country;
        }
        dispatch(saveQuery(options))
            .then(async () => openSnackbar('success', 'Query saved.', () => { }))
            .catch(() => openSnackbar('error', 'An error occured.'))
    };

    const handleOptions = () => {
        let options = {};
        if (!gptSearch) {
            options = {
                q: query.join(";"),
                hl: language,
                engine: engine.toLocaleLowerCase(),
                start: 0,
                string: string,
            };
        } else {
            options = {
                q: string,
                start: 0,
                gpt: true,
            };
        }

        if (engine === "Bing") {
            options.location = country;
        } else {
            options.cr = country;
        }

        updateQuery(options)

        if ((engine === 'Google' || !engine) && engineType) {
            options.engine = engineType
        }
        return options
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (currCharCount >= maxCharCount) return;

        if (query || string) {

            setLoadingResults(true);
            setStatus("initial");
            setShowOptions(false);


            const options = handleOptions()

            dispatch(search(options, (status = "initial")))
                .then(
                    async (data) => {
                        navigate("/search");
                        if (data?.results?.info?.totalPages) {
                            setTotalPages(data.results.info.totalPages);
                            setPadding('0')
                        }
                        setLoadingResults(false);
                    }
                )
                .catch(() => openSnackbar('error', 'An error occured during the search.'))

            setSearch(true);
            setStatus("next");
            clickHistory.setVisitedResults([]);
            clickHistory.setCurrentSelected(null);
            setPageNum(1);
        }
    };

    const handleSearchInput = (e) => {
        const { value } = e.target

        updateQuery({ string: value })
        setString(value)
    }

    const clearSearch = () => {
        updateQuery({ string: "" })
        setString("")
    }

    if (isMobile)
        return <MobileSearchBar setStatus={setStatus} status={status} />;
    else
        return (
            <div
                className={`w-full flex flex-col font-bold rounded transition-all duration-300 ease-in-out items-center justify-center py-${padding} z-50 bg-zinc-800`}
                id="search-bar-inner"
            >
                <SearchForm props={{
                    handleSubmit,
                    gptSearch,
                    setGptSearch,
                    showOptions,
                    setShowOptions,
                    isCurrentSearch,
                    handleSearchInput,
                    clearSearch,
                    handleSaveQuery
                }} />
                <CurrentParameters props={{
                    isCurrentSearch,
                }} />
                <SearchParameterOptions props={{
                    showOptions,
                }} />
            </div>
        );
}

const SelectEngine = ({ props }) => {
    const { searchState } = useContext(SearchContext)
    const { isCurrentSearch } = props
    const { engine, setEngine, updateQuery, currentSearchStatus } = searchState

    const handleEngineSelection = (e) => {
        const { value } = e.target
        updateQuery({ engine: value })
        setEngine(value)
    }

    return (
        <div className="flex flex-row justify-center items-center h-fit">
            <label className="flex items-center h-fit m-0">
                <select
                    onChange={handleEngineSelection}
                    className="rounded ml-1 cursor-pointer bg-transparent text-3xl focus:outline-none text-white"
                >
                    <option
                        selected={"Google" === (isCurrentSearch ? currentSearchStatus.engine : engine)}
                        value={"Google"}
                        defaultValue
                        className="text-black"
                    >
                        Google
                    </option>
                    {/* <option value={"Baidu"}>Baidu</option> */}
                    <option
                        value={"Bing"}
                        selected={"Bing" === (isCurrentSearch ? currentSearchStatus.engine : engine)}
                        className="text-black"
                    >
                        Bing
                    </option>
                    {/* <option value={"Yandex"}>Yandex</option> */}
                </select>
            </label>
        </div>
    )
}

const SelectCountry = () => {
    const { searchState } = useContext(SearchContext)
    const { engine, updateQuery, country, setCountry, currentSearchStatus } = searchState
    const { countries } = settings[engine]

    const handleCountrySelection = (e) => {
        const { value } = e.target
        const newCountry = engine === "Google" ? { cr: countries[value] } : { location: countries[value] }
        updateQuery(newCountry)
        console.log(currentSearchStatus)
        setCountry(countries[value])
    }

    return (
        <div className="px-1">
            <select
                className="p-1 cursor-pointer bg-zinc-900 w-full h-full text-white flex items-center rounded"
                onChange={handleCountrySelection}
            >
                <option
                    // key="country-default"
                    selected={country === ""}
                    disabled
                    className=""
                >
                    Country
                </option>

                {Object.keys(
                    countries
                ).map((name) => (
                    <option
                        // key={`country-${name}`}
                        value={name}
                        selected={
                            countries[name] === country
                        }
                    >
                        {name}
                    </option>
                ))}
            </select>
        </div>
    )
}

const SelectLanguage = () => {
    const { searchState } = useContext(SearchContext)
    const { engine, language, setLanguage, updateQuery } = searchState
    const { languages } = settings[engine]

    const handleLanguageSelection = (e) => {
        const { value } = e.target
        setLanguage(languages[value])
        updateQuery({ hl: languages[value] })
    }
    return (
        (engine === "Google" || engine === "Bing") ? (
            <div className="px-1">
                <select
                    className="cursor-pointer bg-zinc-900 w-full h-full text-white rounded p-1"
                    onChange={handleLanguageSelection}
                >
                    <option
                        key="language-default"
                        selected={language === ""}
                        disabled
                    >
                        Language
                    </option>

                    {Object.keys(languages)
                        .map((name) => (
                            <option
                                key={`language-${name}`}
                                selected={
                                    languages[name] === language
                                }
                                value={name}
                            >
                                {name}
                            </option>
                        ))}
                </select>
            </div>
        ) : <></>
    )
}

const SelectEngineType = () => {
    const { searchState } = useContext(SearchContext);
    const { engineType, engine, updateQuery, setEngineType } = searchState;
    if (!settings[engine].engineTypes) return
    const { engineTypes } = settings[engine];

    const handleEngineTypeSelection = (e) => {
        const value = e.target.value;
        updateQuery({ engineType: value });
        setEngineType(value);
    };

    // Early return if not Google engine
    if (searchState.engine !== "Google") {
        return null;
    }

    return (
        <div className="pl-2 flex flex-row space-x-2 text-white items-center">
            <Select
                className="p-0"
                onChange={handleEngineTypeSelection}
                renderValue={(selected) => {
                    // Ensure we have a valid selected value
                    const selectedType = engineTypes[selected];
                    if (!selectedType) return null;

                    return (
                        <img
                            src={selectedType.img}
                            alt={selectedType.alt}
                            className="w-12"
                        />
                    );
                }}
                value={engineType} // Provide a fallback empty string
                displayEmpty
                MenuProps={{
                    sx: {
                        "& .MuiMenu-list": {
                            padding: "0px", // Remove default padding
                        },
                    }
                }}
                sx={{
                    padding: "0 !important",
                    "& .MuiSelect-root": {
                        padding: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                        padding: 1, // Remove padding inside the input
                    },
                    "& .MuiSelect-icon": {
                        color: "white", // Change the color of the dropdown arrow
                    }
                }}
            >
                {Object.entries(engineTypes).map(([key, type]) => {
                    const { text, img, alt, engine } = type;
                    return (
                        <Tooltip
                            key={`tooltip-${key}`}
                            title={<p className="text-lg">{text}</p>}
                            arrow
                            placement="right"
                            value={engine}
                        >
                            <MenuItem
                                key={`language-${key}`}
                                sx={{
                                    backgroundColor: "#3f3f46",
                                    margin: '0px',
                                    '&.Mui-selected': {
                                        backgroundColor: '#71717a'
                                    },
                                    "&.Mui-selected:hover": {
                                        backgroundColor: '#71717a'
                                    },
                                    "&.Mui-selected:focus": {
                                        backgroundColor: '#71717a'
                                    },
                                    '&:hover': {
                                        backgroundColor: '#71717a'
                                    },
                                }}
                            >
                                <img src={img} alt={alt} className="w-8" />
                            </MenuItem>
                        </Tooltip>
                    );
                })}
            </Select>
        </div>
    );
};

const SearchForm = ({ props }) => {
    const {
        handleSubmit,
        gptSearch,
        setGptSearch,
        showOptions,
        setShowOptions,
        isCurrentSearch,
        handleSaveQuery,
    } = props

    const {
        searchState,
        queryLen,
        hasReachCharLimit,
        currCharCount,
        maxCharCount
    } = useContext(SearchContext)

    const {
        updateQuery,
        setString,
        query,
        setQuery,
        string,
        currentSearchStatus
    } = searchState

    const handleSearchInput = (e) => {
        updateQuery({ string: e.target.value })
        setString(e.target.value)
    }

    const clearSearch = () => {
        updateQuery({ string: "" })
        setString("")
    }

    return (
        <form
            className={`w-full flex items-center justify-center`}
            data-collapse-target="collapse"
            onSubmit={handleSubmit}
        >
            <div className="flex items-center w-3/5 h-fit justify-center p-2">
                <div
                    className={`flex flex-row h-fit items-center w-full`}
                >
                    {!isProduction && (
                        <div
                            className={`w-12 h-5 ${gptSearch
                                ? "bg-green-500 justify-end"
                                : "bg-zinc-500 justify-start"
                                } rounded-full flex cursor-pointer`}
                            onClick={() => setGptSearch(!gptSearch)}
                        >
                            <div
                                className={`h-5 w-5 rounded-full bg-zinc-200`}
                            ></div>
                        </div>
                    )}
                    <img
                        src={arrowForward}
                        className={`h-[2.5vh] w-8 flex flex-row transition-all duration-300 ease-in-out z-20 ${showOptions ? "rotate-90" : ""
                            } cursor-pointer`}
                        onClick={() => setShowOptions(!showOptions)}
                        alt="show options"
                    />
                    <div
                        className={`flex flex-row jusitfy-center h-fit w-full items-center`}
                    >
                        <div
                            className={`flex w-full rounded-full px-2 py-1 justify-between bg-white/5 backdrop-blur-xl items-center h-fit mr-1`}
                        >
                            <SelectEngine props={{
                                isCurrentSearch
                            }} />

                            {/* <SelectEngineType /> */}
                            <input
                                placeholder="Search"
                                className={`px-1 bg-white/0 rounded w-full outline-none h-full text-white poppins-light text-lg`}
                                value={isCurrentSearch ? currentSearchStatus.string : string}
                                onChange={handleSearchInput}
                                onClick={() => setShowOptions(true)}
                                key="main-search"
                            // autoFocus
                            />
                            {string?.length ? (
                                <img
                                    src={clearText}
                                    className="h-10 cursor-pointer"
                                    onClick={clearSearch}
                                    alt='Clear search.'
                                />
                            ) : (
                                <></>
                            )}
                            {currCharCount >= maxCharCount - 100 ? <div
                                className={`${currCharCount >= maxCharCount
                                    ? "!text-red-400"
                                    : "!text-amber-400"
                                    }  `}
                            >
                                <p>
                                    {currCharCount}/{maxCharCount}
                                </p>
                            </div> : <></>}
                        </div>
                        {queryLen() && !hasReachCharLimit() ? (
                            <button
                                className="flex justify-self-end p-1 mx-1 hover:bg-zinc-700 rounded-full focus:outline-none"
                                type="submit"
                            >
                                <img src={searchIcon} className="h-10" alt="submit search" />
                            </button>
                        ) : (
                            <div className="px-3 mx-1">
                                <div className="w-6" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-row w-fit">
                    {queryLen() ? (
                        <div className="flex flex-row align-items-center justiify between px-2">
                            {hasReachCharLimit() ? (
                                <></>
                            ) : (
                                <img
                                    className="h-8 cursor-pointer px-2"
                                    src={saveIcon}
                                    onClick={handleSaveQuery}
                                    alt="save query"
                                />
                            )}
                            {query?.length ? <p
                                className={`text-white rounded h-8 flex align-items-center hover:text-slate-900 cursor-pointer`}
                                onClick={() => setQuery([])}
                            >
                                Clear
                            </p> : <></>}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </form>
    )
}

const SearchParameterOptions = ({ props }) => {
    const { showOptions } = props

    return (
        showOptions && (
            <div className={`flex flex-row w-3/5 flex-wrap`}>
                <div className={`w-full grid grid-cols-3`}>
                    <SelectLanguage />
                    <SelectCountry />
                    <Operators />
                </div>
            </div>
        )
    )
}

const Operators = () => {
    const [selectedOperator, setSelectedOperator] = useState(null);
    const { searchState } = useContext(SearchContext)
    const { engine } = searchState
    const { operators } = settings[engine]

    return (
        Object.entries(operators).map(
            ([text, param], index) => {
                return (
                    <Parameter
                        // key={`param-${param}`}
                        index={index}
                        text={text}
                        param={param}
                        selectedOperator={selectedOperator}
                        setSelectedOperator={setSelectedOperator}
                    />
                )
            }
        )
    )
}

const CurrentParameters = ({ props }) => {
    const { isCurrentSearch } = props
    const { searchState } = useContext(SearchContext)
    const { query, currentSearchStatus } = searchState
    const currentQuery = () => !isCurrentSearch ? query : currentSearchStatus.query

    const parseParameter = (param) => {
        const operator = param.split(":")[0]
        const value = param.split(":")[1]?.split('"').join("") || ""
        return [operator, value]
    }

    return (
        query?.length ? (
            <div className="flex flex-wrap p-1 w-3/5">
                {currentQuery().map((parameter, index) => {
                    if (parameter.includes(":")) {
                        const [param, value] = parseParameter(parameter)
                        return (
                            <QueryParam
                                key={`query-param-${index}`}
                                param={param}
                                value={value}
                                index={index}
                            />
                        );
                    }
                    return <></>
                })}
            </div>
        ) : (
            <></>
        )
    )
}
