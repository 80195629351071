import { createContext, useState, useEffect } from "react";
import Archive from "../components/Browser/archive";
import GptDocAnalyze from "../components/Browser/GptDocAnalyze";
import copyIcon from "../assets/images/copy.png"

export const BrowserContext = createContext();

const parseUrl = (url) => {
    if (url.includes('https://docs.google.com/gview?embedded=true&url=')) {
        return url.split('https://docs.google.com/gview?embedded=true&url=')[1]
    }
    else return url
}

export const BrowserProvider = ({ children }) => {
    const [component, setComponent] = useState("");
    const [displayUrl, setDisplayUrl] = useState("");
    const [components, setComponents] = useState({
        browser: (props) => <Browser props={{
            url: props.url
        }} />,
        archive: (props) => <ArchiveWithHeader props={{
            url: props.url
        }} />,
        analyze: (props) => (
            <GptDocAnalyze
                url={parseUrl(props.url)}
            />
        ),
    });

    return (
        <BrowserContext.Provider
            value={{
                component,
                setComponent,
                displayUrl,
                setDisplayUrl,
                components
            }}
        >
            {children}
        </BrowserContext.Provider>
    )
}

const Browser = ({ props }) => {
    const { url } = props

    return (
        <div className="flex flex-col h-full w-full">
            <Header props={{ url}} />
            <iframe className={`h-full w-full`} src={url} title="browser" />
        </div>
    )
}

const ArchiveWithHeader = ({ props }) => {
    const { url } = props

    return (
        <div className="h-full w-full">
            <Header props={{
                url
            }} />
            <Archive url={url} />
        </div>
    )
}

const Header = ({ props }) => {
    const { url } = props
    const [copied, setCopied] = useState(false)

    const copyToClipboard = () => {
        navigator.clipboard.writeText(parseUrl(url))
        setCopied(true)
    };

    useEffect(() => {
        setCopied(false)
    }, [url])

    return (
        <div className={`bg-zinc-600 flex flex-row items-center justify-content-between w-full p-2 h-fit overflow-hidden`}>
            <div className="w-full flex flex-row justify-content-between h-fit bg-slate-100 p-1 rounded overflow-hidden text-black items-center">
                <input className="w-full rounded overflow-scroll no-scrollbar flex items-center" value={parseUrl(url)} disabled />
                <div className="p-1 bg-zinc-300 rounded">
                    {copied ? (
                        <p>Copied!</p>
                    ) : (
                        <img
                            alt="copy url"
                            className="cursor-pointer h-6"
                            src={copyIcon}
                            onClick={copyToClipboard}
                        />
                    )}

                </div>
            </div>
        </div>
    )
}
