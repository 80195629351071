import { useState, useEffect, useContext } from "react";
import { io } from "socket.io-client";
import { ChatContext } from "../../context/ChatContext";
import ReactMarkdown from "react-markdown";
import docIcon from "../../assets/images/document.png"
import downloadIcon from "../../assets/images/download.png"
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from "@mui/material";

export default function GptDocAnalyze({ url }) {
  const {
    socketRef,
    messages,
    setMessages,
    chatContainerRef,
    responseRef,
    chatRef
  } = useContext(ChatContext)
  let fileName = ''
  if (url?.length) {
    fileName = url.split('/').slice(-1)[0]
  }
  const [file, setFile] = useState({ metadata: { name: fileName || null }, url: url || null })

  useEffect(() => {
    responseRef.current = "";
    socketRef.current = io("http://localhost:8000");

    socketRef.current.on("connect", () => {
      // console.log("connected");
      socketRef.current.emit("join", 1);
      // console.log("room 1 joined by client");

      if (!responseRef.current) {
        // socketRef.current.emit("url", { url });
      }
    });

    socketRef.current.on("message", (newMessage) => {
      handleSystemMessage(newMessage, setMessages, responseRef, messages)
    });

    socketRef.current.on("end", () => {
      responseRef.current = "";
    });

    // Cleanup
    return () => {
      socketRef.current.disconnect();
      console.log("Socket disconnected on component unmount");
    };
  }, [url]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className={`h-full w-full flex flex-col bg-zinc-900 rounded`} ref={chatRef}>
      <ChatLog />
      <ChatInput props={{
        file,
        setFile
      }} />
    </div>
  );
}

const ChatLog = () => {
  const { chatContainerRef, messages } = useContext(ChatContext)
  return (
    <div className="flex-1 overflow-y-scroll bg-zinc-900 h-full rounded-lg">
      <div
        ref={chatContainerRef}
        className="h-full overflow-y-auto p-4 space-y-4 styled-scrollbar"
      >

        {messages.map((msg) => {
          const { role, topics, content, results, downloadLink } = msg
          if (!topics && !content && !results && !downloadLink) return;

          return (
            <div
              // key={index}
              className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-xs lg:max-w-md xl:max-w-lg px-4 py-2 rounded-lg shadow-lg ${role === 'user'
                  ? 'bg-blue-500 text-white'
                  : 'bg-zinc-700 text-white'
                  }`}
              >
                <ReactMarkdown className="whitespace-pre text-wrap">{content}</ReactMarkdown>
                <TopicList props={{ topics }} />
                <ResultList props={{
                  results,
                  downloadLink
                }} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const ChatInput = ({ props }) => {
  const { file, setFile } = props
  const { message, socketRef, setMessages, setMessage } = useContext(ChatContext)

  const handleFile = async (e) => {
    const file = await e.target.files[0]
    const { name, type, size } = file
    const arrayBuffer = await file.arrayBuffer()
    const buffer = new Uint8Array(arrayBuffer);
    const metadata = { name, type, size }
    setFile({ metadata, buffer })
    // socketRef.current.emit('fileBuffer', { buffer, metadata })
  }

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const sendMessage = (e) => {
    e.preventDefault()
    if (message.trim() === "") return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: message },
    ]);
    socketRef.current.emit("message", message);
    setMessage('');
  };

  return (
    <div className="flex flex-col p-2">
      <InitialPrompt props={{
        file
      }} />
      <form
        className="flex space-x-2 w-full p-3 items-center bg-zinc-800 rounded-lg shadow-lg"
        onSubmit={sendMessage}
      >
        <label for="file-upload" class="p-2 mb-0 rounded-lg cursor-pointer flex items-center">
          {/* <img src={docIcon} className="h-8" /> */}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-8 h-8 fill-none hover:fill-white stroke-white stroke-2" >
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
          </svg>


          <input id="file-upload" type="file" style={{ display: 'none' }} className='' accept='.pdf, .doc, .docx, .ppt, .pptx' onChange={handleFile} />
        </label>
        <textarea
          type="text"
          value={message}
          onChange={(e) => handleChange(e)}
          placeholder="Type your message..."
          className="flex-1 p-2 focus:outline-none bg-zinc-800 text-white w-full flex items-center"
          rows="2"
        />
        <button
          className={`p-2 text-white rounded-lg ${message.length ? 'hover:bg-blue-600 bg-blue-500' : 'bg-zinc-500'} focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center`}
          type="submit"
        >
          Send
        </button>
      </form>
    </div>
  )
}

const InitialPrompt = ({ props }) => {
  const { file } = props
  const { socketRef, setMessages, messages, setSelectedTopics } = useContext(ChatContext)

  const extractTopics = (action) => {
    socketRef.current.emit("url", { file, action });
    const updatedMessages = [...messages]
    updatedMessages.push({ role: "user", content: action })
    setMessages(updatedMessages)
    setSelectedTopics([])
  }

  const actions = [
    {
      text: 'Extract topics',
      actionFunc: (text) => extractTopics(text),
    },
    {
      text: 'Summarize',
      actionFunc: (text) => extractTopics(text),
    },
  ]
  return (
    <div
      className="w-full flex flex-row space-x-2 rounded-lg px-4 py-2 items-center shadow-lg"
    >

      <div
        className="flex flex-row w-fit rounded border-2 border-zinc-700 items-center justify-center space-x-1 p-1 px-2 text-white"
      >
        <img
          alt='Document'
          src={docIcon}
          className="h-8"
        />
        <p className="w-fit">{file?.metadata?.name}</p>
      </div>
      <div className="flex flex-row space-x-1 w-fit">
        {
          Object.values(actions).map(action => {
            const { text, actionFunc } = action
            return (
              <div
                className="rounded border-2 border-blue-500 flex items-center justify-center w-fit p-1 bg-blue-500 text-white hover:bg-blue-400 cursor-pointer"
                onClick={() => actionFunc(text)}
              >
                <p>{text}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const TopicList = ({ props }) => {
  const [selectTopics, setSelectTopics] = useState(false)
  const [action, setAction] = useState("")
  const [showSubmit, setShowSubmit] = useState(false)
  const { selectedTopics, socketRef, setMessage, responseRef, messages, setMessages, message } = useContext(ChatContext)

  const sendTopics = () => {
    const topics = action === 'Select' ? selectedTopics : props?.topics
    const updatedMessages = [...messages]
    updatedMessages.push({ content: message, role: "user" })
    setMessages(updatedMessages)
    socketRef.current.emit("topics", { topics })
    setSelectTopics(false)
    setAction("")
    setMessage("")
    responseRef.current = ""
  }

  useEffect(() => {
    if (selectTopics && selectedTopics?.length) setShowSubmit(true)
    else setShowSubmit(false)
  }, [selectTopics, selectedTopics])

  useEffect(() => {
    action === 'Select' ? setSelectTopics(true) : setSelectTopics(false)
  }, [action])

  return props?.topics?.length ? (
    <div className="flex flex-col p-2">
      <div className="space-x-1">
        {action ?
          <SelectedAction props={{
            action,
            setAction,
            setSelectTopics
          }} /> :
          <ActionButtons props={{
            setAction,
            sendTopics: () => sendTopics()
          }} />}

      </div>
      <div className="flex flex-col p-2 divide-y">

        {props.topics.map(topic => {
          return (
            <TopicCard props={{
              topic,
              selectTopics
            }} />
          )
        })}
      </div>
      {showSubmit ?
        <div
          className="flex items-center justify-center"
        >
          <button
            className="p-1 rounded bg-blue-500 hover:bg-blue-400 w-fit"
            onClick={sendTopics}
          >
            Submit
          </button>
        </div> : <></>}
    </div>
  ) : <></>
}

const TopicCard = ({ props }) => {
  const { selectTopics, topic } = props
  const { selectedTopics, setSelectedTopics, setMessage } = useContext(ChatContext)
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    const topics = [...selectedTopics]
    if (selected) {
      topics.push(topic)
      setSelectedTopics([...topics])
      setMessage(`Research these topics... ${topics.join(', ')}`)
    } else {
      const updatedTopics = topics.filter(prevTopic => prevTopic !== topic)
      setSelectedTopics([...updatedTopics])
    }
  }, [selected])

  return (
    <div className="p-1 flex flex-row space-x-2 cursor-pointer hover:bg-zinc-600" onClick={() => setSelected(!selected)}>
      {selectTopics ? <div className={`w-6 h-6 rounded-full border-2 ${selected ? 'bg-green-500' : ''}`}></div> : <></>}
      <p>{topic}</p>
    </div>
  )
}

const ResultList = ({ props }) => {
  const { results, downloadLink } = props

  return results?.length ? (
    <div className="flex flex-col p-2">
      <div className="w-full flex flex-row justify-between items-center text-xl py-1">
        <p>Results: </p>
        {downloadLink ? <a href={downloadLink} download='test.pdf'>
          <img src={downloadIcon} className="w-7" />
        </a> : <></>}
      </div>
      <div className="flex flex-col p-2 bg-zinc-800 rounded-lg">

        {results?.length ? results.map(result => {


          return (
            <ResultCard props={{
              result
            }} />
          )
        }) : <></>}
      </div>
    </div>
  ) : <></>
}

const ResultCard = ({ props }) => {
  const { result } = props

  if (!result) return;

  const { topic, results } = result

  return (
    <div className="p-1 flex flex-col space-x-2">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {topic}
        </AccordionSummary>
        <AccordionDetails className="flex flex-col p-2 divide-y">
            {results?.map(result => {
              const { link, title, snippet, source } = result
              return (
                <Tooltip
                  title={
                    <div className="flex flex-col space-y-1 p-1">
                      <p className="text-lg text-zinc-300 underline">{source}</p>
                      <p className="text-lg">{snippet}</p>
                    </div>
                  }
                  placement="right"
                  arrow
                >
                  <a className="p-1" href={link} target="_blank">{title}</a>
                </Tooltip>
              )
            })}
        </AccordionDetails>
      </Accordion>
      {/* <div
        onClick={() => setShowResults(!showResults)}
        className="cursor-pointer hover:bg-zinc-600 p-2 text-lg rounded-lg text-blue-500"
      >
        <p>{topic}</p>
      </div>
      {showResults ? <div className="flex flex-col p-2 divide-y">
        {results?.map(result => {
          const {link, title, snippet, source} = result
          return (
            <Tooltip
              title={
                <div className="flex flex-col space-y-1 p-1">
                  <p className="text-lg text-zinc-300 underline">{source}</p>
                  <p className="text-lg">{snippet}</p>
                </div>
              }
              placement="right"
              arrow
            >
                <a className="p-1" href={link}>{title}</a>
            </Tooltip>
          )
        })}
      </div> : <></>} */}
    </div>
  )
}

const SelectedAction = ({ props }) => {
  const { action, setAction, setSelectTopics } = props
  const cancelSelection = () => {
    setSelectTopics(false)
    setAction("")
  }

  const actionIsSelect = () => action === 'Select' ? true : false

  return (
    <div className="flex flex-row space-x-2 items-center">
      <p className="poppins-bold underline text-lg text-green-500">{action} Topics</p>
      {actionIsSelect() ?
        <button onClick={cancelSelection} className="px-1">
          Cancel
        </button> : <></>}
    </div>
  )
}

const ActionButtons = ({ props }) => {
  const { setAction, sendTopics } = props
  return (
    <>
      <button
        className="rounded bg-blue-500 p-1 px-2 hover:bg-blue-400 text-white"
        onClick={() => {
          setAction("All Topics")
          sendTopics()
        }}
      >
        All
      </button>
      <button
        className="rounded bg-blue-500 p-1 px-2 hover:bg-blue-400 text-white"
        onClick={() => setAction("Select")}
      >
        Select
      </button>
    </>
  )
}

const handleSystemMessage = (newMessage, setMessages, responseRef) => {
  const { content, topics, results, stream, buffer } = newMessage;
  const response = responseRef.current
  responseRef.current += stream ? content : `${content}\n`; // Append new content to responseRef

  setMessages((prevMessages) => {
    // Copy the previous messages array to avoid mutations
    const updatedMessages = prevMessages.map((msg) => ({ ...msg }));

    // Find the index of the last system message
    const lastSystemMsgIdx = updatedMessages
      .map((msg, idx) => (msg.role === "system" ? idx : -1))
      .filter((idx) => idx !== -1)
      .pop();

    if (response?.length) {
      // Update the content of the last system message if it exists
      updatedMessages[lastSystemMsgIdx].content = responseRef.current;
      updatedMessages[lastSystemMsgIdx].topics = topics;
      if (updatedMessages[lastSystemMsgIdx]?.results?.length) {
        updatedMessages[lastSystemMsgIdx].results = updatedMessages[lastSystemMsgIdx].results.concat(results)
      }
      if (buffer) {
        const blob = new Blob([buffer], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);
        updatedMessages[lastSystemMsgIdx].downloadLink = url
      }

    } else {
      // If no system message exists, add a new one
      updatedMessages.push({ role: "system", content: responseRef.current, topics, results });
    }

    return updatedMessages;
  });
};
