import { useContext } from "react";
import { SearchContext } from "../../context/SearchContext";
import { ResultsContext } from "../../context/ResultsContext";
import { isMobile } from "react-device-detect";
import ResultCard from "./ResultCard";
import loadingIcon from "../../assets/icons/loading.png"

const Loading = () => (
    <div className="flex justify-center items-center w-full h-full">
        <img src={loadingIcon} className="h-26 w-26 rounded-full animate-spin mb-4" alt="loading" />
    </div>
)

export default function Results({ data, selectResources, resultsRef, resultsWidth, resultsHeight }) {
    const { loadingResults } = useContext(SearchContext);
    const { showResult } = useContext(ResultsContext);
    const isSearch = window.location.pathname.includes('results')
    const isViewAll = !window.location.pathname.includes('saved')

    const handleClassname = () => `${isMobile ? "overflow-x-hidden" : "overflow-y-auto mr-2"} ${isMobile && !showResult ? "row-span-2" : ""}`

    return (
        <div
            className={`flex flex-col h-${resultsHeight} items-center w-${resultsWidth} ${handleClassname()}`}
            id="results"
            ref={resultsRef}
        >
            {loadingResults ? (
                <Loading />
            ) : (
                <DisplayData props={{
                    showResult,
                    data,
                    isSearch,
                    isViewAll,
                    selectResources
                }}/>
            )}
        </div>
    )
}
const DisplayData = ({props}) => {
    const {showResult, data, isSearch, isViewAll, selectResources} = props
    const handleClassname = () => showResult || isMobile ? `w-full` : "3xl:w-1/2 2xl:w-1/2 xl:w-full lg:w-full md:w-full"

    return (
        <div className={`${handleClassname()} p-2 h-full space-y-3`}>
            {data && Object.values(data).length ? Object.keys(data)
                .reverse()
                .filter((key) => !data[key].currentPage)
                .map((rowKey, index) => (
                    <ResultCard key={rowKey} rowKey={rowKey} data={data} index={index} selectResources={selectResources} />
                )) :
                <div className="flex justify-center items-center text-white w-full text-xl h-full">
                    {isSearch ? (isViewAll ? "Saved and viewed resources will show up here" : "Saved resources will show up here") : "No results"}
                </div>
            }
        </div>
    )
}
