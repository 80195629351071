import { useState, useRef, useContext, useCallback } from "react";
import { SearchContext } from "../../context/SearchContext";
import trashIcon from "../../assets/images/trash.png"

export default function QueryParam({ param, value, index }) {
  const queryParamRef = useRef(null);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [paramValue, setParamValue] = useState(value);
  const { searchState } = useContext(SearchContext);
  const { setQuery } = searchState

  const handleDelete = useCallback(() => {
    setQuery(prevQuery => {
      return prevQuery.filter(p => p !== `${param}:${value}`)
    });
  }, [searchState, param]);

  const handleParamValueChange = useCallback((e) => {
    const newValue = e.target.value;
    setParamValue(newValue);
    setQuery(prevQuery => {
      const newQuery = [...prevQuery];
      newQuery[index] = `${param}:${newValue}`;
      return newQuery;
    });
  }, [param, index, searchState]);

  return (
    <div
      className={`p-1 flex justify-content-end items-center rounded mr-1 mt-1 h-fit w-fit ${
        param.includes("-") ? "bg-amber-700" : "bg-blue-500"
      }`}
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
      id="query-param"
      ref={queryParamRef}
    >
      <div className="flex flex-row w-fit">
        <div className="w-fit flex text-white text-xl px-1 border-b">
          <p>{param}: </p>
        </div>
        <div>
          <input
            className="flex text-white rounded ml-1 w-32 outline-none px-1 bg-zinc-800 h-full poppins-light"
            value={paramValue}
            onChange={handleParamValueChange}
          />
        </div>
      </div>
      <div className="flex w-7">
        {showDeleteIcon ? (
          <img
            src={trashIcon}
            onClick={handleDelete}
            className="flex h-6 w-8 rounded ml-1 hover:bg-red-600 align-self-start cursor-pointer"
            alt="Delete"
          />
        ) : <></>}
      </div>
    </div>
  );
}
