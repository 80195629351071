import googleLanguages from "./google-languages.json";
import googleCountries from "./google-countries.json";
import webIcon from '../../../../assets/images/web.png'
import videoIcon from '../../../../assets/images/video.png'

const googleOperators = {
  "Site:": { "text": "site:", "example": ".edu", "title": "Enter a domain to find results from that site only" },
  "In title:": { "text": "intitle:", "example": "research paper", "title": "Enter keywords to find results with this text in the title" },
  "In url:": { "text": "inurl:", "example": "pdf", "title": "Enter keywords to find results with this text in the URL" },
  "Include text:": { "text": "intext:", "example": "organic chemistry", "title": "Enter keywords to find results with this text in the page content" },
  "Exclude site:": { "text": "-site:", "example": "wikipedia.org", "title": "Enter a domain to exclude results from that site" },
  "Exclude from title:": { "text": "-intitle:", "example": "login", "title": "Enter keywords to exclude results with this text in the title" },
  "Exclude from url:": { "text": "-inurl:", "example": "forum", "title": "Enter keywords to exclude results with this text in the URL" },
  "Exclude from text:": { "text": "-intext:", "example": "advertisement", "title": "Enter keywords to exclude results with this text in the page content" },
  "Before": { "text": "before:", "example": "YYYY-MM-DD or YYYY-MM or YYYY", "title": "Results from before this date." },
  "After": { "text": "after:", "example": "YYYY-MM-DD or YYYY-MM or YYYY", "title": "Results from after this date." }
};

const engineTypes = {
    'google': {
      text: 'Web',
      engine: 'google',
      img: webIcon,
      alt: 'Web Results'
    },
    'google_video': {
      text: 'Video',
      engine: 'google_video',
      img: videoIcon,
      alt: 'Video Results'
    }
}

export const googleSettings = { operators: googleOperators, countries: googleCountries, languages: googleLanguages, engineTypes }
