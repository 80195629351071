import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { SearchContext } from "../../context/SearchContext";
import * as resultActions from '../../store/result'
import searchIcon from "../../assets/images/search.png";

export default function ResultsPageFilters() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { setLoadingResults } = useContext(SearchContext)
    const { view } = params;
    const [filterInput, setFilterInput] = useState("");
    const [limit, setLimit] = useState(25);
    const viewAll = view === "all" ? true : (view === 'saved' ? false : true)

    useEffect(() => {

        const options = { limit, saved: !viewAll };
        if (filterInput) options.filter = filterInput;
        console.log(options)

        setLoadingResults(true)
        dispatch(resultActions.getallResults(options))
            .then(async () => {
                setLoadingResults(false)
            })

    }, [dispatch, limit, view, viewAll]);


    const handleSubmit = (e) => {
        e.preventDefault();
        const options = { limit, saved: !viewAll };
        if (filterInput) options.filter = filterInput;
        setLoadingResults(true)
        dispatch(resultActions.getallResults(options))
            .then(async () => {
                setLoadingResults(false)
            })
    };


    return (
        <form
        className={`flex justify-center items-center text-white w-full`}
        onSubmit={handleSubmit}
        >
            <div className="flex flex-row p-2 items-center space-x-2 w-full">
                <FilterInput props={{
                    filterInput,
                    setFilterInput
                }}/>
                <SelectAllOrSaved props={{
                    viewAll
                }}/>
                <SelectLimit props={{
                    limit,
                    setLimit
                }}/>
            </div>
        </form>
    )
}

const FilterInput = ({props}) => {
    const {filterInput, setFilterInput} = props

    const handleInput = (e) => setFilterInput(e.target.value.toLowerCase())

    return (
        <div
            className={`rounded-full px-2 py-1 flex justify-between w-full my-2 bg-white/5 backdrop-blur-xl`}
        >
            <input
                className="px-1 bg-white/0 rounded w-full outline-none h-full text-white poppins-light text-lg"
                placeholder="Filter Resources"
                value={filterInput}
                onChange={handleInput}
            />
            <button
                type="submit"
                className="text-black focus:outline-none cursor-pointer rounded-full h-7 w-7"
            >
                <img src={searchIcon} className="h-6 w-6 transition-all duration-200 hover:h-7 hover:w-7" alt="Submit search." />
            </button>
        </div>
    )
}

const SelectAllOrSaved = ({props}) => {
    const {viewAll} = props
    const navigate = useNavigate()

    const allButtonStyle = () => viewAll ? "border-b-4" : "hover:bg-zinc-600 hover:border-b-4 hover:border-gray-400"

    const savedButtonStyle = () => viewAll ? "hover:bg-zinc-600 hover:border-b-4 hover:border-gray-400" : "border-b-4"


    return (
        <div className="flex flex-row w-fit rounded text-lg">
            <p
                onClick={() => navigate('/results/all')}
                className={`px-1 cursor-pointer ${allButtonStyle()}`}
            >
                All
            </p>
            <p
                onClick={() => navigate('/results/saved')}
                className={`px-1 cursor-pointer ${savedButtonStyle()}`}
            >
                Saved
            </p>
        </div>
    )
}

const SelectLimit = ({props}) => {
    const {limit, setLimit} = props

    const handleLimitSelection = (e) => setLimit(Number(e.target.value))

    return (
        <div>
            <select
                className="mx-2 text-slate-600 cursor-pointer rounded text-lg"
                onChange={handleLimitSelection}
                value={limit}
            >
                <option>25</option>
                <option>50</option>
                <option>100</option>
            </select>
        </div>
    )
}
