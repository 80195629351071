import { useState, createContext, useRef } from "react";

export const ChatContext = createContext()

export const ChatProvider = ({ children }) => {
    const socketRef = useRef(null);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([
        { role: "system", content: `What would you like me to do with this document?` },
    ]);
    const [selectedTopics, setSelectedTopics] = useState([])
    const chatContainerRef = useRef(null);
    const responseRef = useRef("");
    const chatRef = useRef(null)

    return (
        <ChatContext.Provider
            value={{
                socketRef,
                message,
                setMessage,
                messages,
                setMessages,
                selectedTopics,
                setSelectedTopics,
                chatContainerRef,
                responseRef,
                chatRef
            }}
        >
            {children}
        </ChatContext.Provider>
    )
}
