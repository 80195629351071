import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { hasGrantedAnyScopeGoogle } from "@react-oauth/google";
import { useSnackbar } from "../../context/Snackbar";
import { Container, TextField, Button, Typography, Grid } from '@mui/material';
import * as sessionActions from "../../store/session";
import googleLogo from "../../assets/images/google-logo.png";

function LoginFormPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [credential, setCredential] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const { openSnackbar } = useSnackbar()
    const sessionUser = useSelector(state => state.session.user)

    useEffect(() => {
        if (sessionUser) {
            navigate('/search')
        }
    }, [sessionUser, navigate])

    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            const hasAccess = hasGrantedAnyScopeGoogle(
                tokenResponse,
                "openid",
                "email",
                "profile"
            );
            if (hasAccess) {
                dispatch(sessionActions.login({ token: tokenResponse })).then(
                    async (data) => {
                        if (data && !data.errors) {
                            navigate("/search");
                        }
                    }
                ).catch(() => {
                    // const data = await res.json()
                    openSnackbar('error', 'There was a problem with authentication.')
                    // if (data && data.errors) {
                    //     setErrors(data.errors);
                    // }
                })
            }
        },
        onError: () => {
            openSnackbar('error', 'There was a problem with authentication.')
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        return dispatch(sessionActions.login({ credential, password })).then(
            async (data) => {
                if (data && data.errors) {
                    setErrors(data.errors);
                } else {
                    navigate("/search");
                }
            }
        ).catch(async (res) => {
            const data = await res.json();
            openSnackbar("error", data?.errors?.credential || "Login failed.");
        })
    };

    const handleforgotPassword = () => {
        navigate('/forgot-password')
    }



    return (
        <div className={`h-full w-full flex items-center justify-center bg-zinc-900`}>
            <div className={`w-full max-w-md p-8 space-y-8 bg-zinc-800 rounded-lg shadow-lg`}>
                <h2 className="text-3xl text-center text-white">Log In</h2>
                <form onSubmit={handleSubmit} className="space-y-6 w-full">
                    <CredentialInput props={{ credential, setCredential, errors }} />
                    <PasswordInput props={{ password, setPassword, errors }} />
                    <SubmitButton />
                    <ForgotPasswordButton
                        props={{
                            handleforgotPassword
                        }}
                    />
                </form>
                <GoogleSignIn
                    props={{
                        googleLogo,
                        login
                    }}
                />
            </div>
        </div>
    );
}
const CredentialInput = ({ props }) => {
    const { credential, setCredential, errors } = props
    return (
        <div className="w-full">
            <label htmlFor="credential" className="block text-sm font-medium text-zinc-300">Username or Email</label>
            <input
                id="credential"
                name="credential"
                type="text"
                value={credential}
                onChange={(e) => setCredential(e.target.value)}
                required
                placeholder="Username or Email"
                className="mt-1 block w-full px-3 py-2 bg-zinc-700 text-white rounded-md focus:outline-none focus:ring focus:ring-zinc-500"
            />
            {/* <TextField
                        variant="outline"
                        sx={{width: '100%'}}
                    /> */}
            {errors.credential && (
                <p className="mt-2 text-sm text-red-500">{errors.credential}</p>
            )}
        </div>
    )
}

const PasswordInput = ({ props }) => {
    const { password, setPassword, errors } = props
    return (
        <div>
            <label htmlFor="password" className="block text-sm font-medium text-zinc-300">Password</label>
            <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Password"
                className="mt-1 block w-full px-3 py-2 bg-zinc-700 text-white rounded-md focus:outline-none focus:ring focus:ring-zinc-500"
            />
            {errors.password && (
                <p className="mt-2 text-sm text-red-500">{errors.password}</p>
            )}
        </div>
    )
}

const SubmitButton = () => {
    return (
        <div>
            <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-zinc-500"
            >
                Log In
            </button>
        </div>
    )
}

const ForgotPasswordButton = ({ props }) => {
    const { handleforgotPassword } = props
    return (
        <div className="w-full p-2 text-blue-500 cursor-pointer" onClick={handleforgotPassword}>
            <p>Forgot Password</p>
        </div>
    )
}

const GoogleSignIn = ({ props }) => {
    const { googleLogo, login } = props
    return (
        <div className="flex items-center justify-center">
            <div
                onClick={() => login()}
                className="w-full py-2 px-4 bg-zinc-600 text-white font-semibold rounded-md hover:bg-zinc-500 focus:outline-none focus:ring focus:ring-zinc-500 flex items-center justify-center cursor-pointer"
            >
                <img src={googleLogo} className="h-5 mr-2" alt="Google logo" />
                <p>Sign in with Google</p>
            </div>
        </div>
    )
}

export default LoginFormPage;
