import { useState, useEffect, useContext } from "react";
import { BrowserContext } from "../../context/BroswerContext";
import { ResultsContext } from "../../context/ResultsContext";
import { isMobile } from "react-device-detect";
import copyIcon from "../../assets/images/copy.png"
import historyIcon from "../../assets/images/history.svg"
import chatIcon from "../../assets/images/chat.png"
import webIcon from "../../assets/images/web.png"
import Tooltip from '@mui/material/Tooltip';

const isProduction = process.env.NODE_ENV === 'production'

export default function BrowserHeader() {
  const { preview } = useContext(ResultsContext)
  const { setComponent } = useContext(BrowserContext)

  useEffect(() => {
    setComponent('browser')
  }, [preview])

  const buttons = [
    {
      name: "browser",
      toolTip: "Web Browser",
      img: webIcon,
      imgAlt: 'Web Browser.'
    },
    {
      name: "archive",
      toolTip: "Archive",
      img: historyIcon,
      imgAlt: 'Archive Snapshot.'
    },
    {
      name: "analyze",
      toolTip: "GPT",
      img: chatIcon,
      imgAlt: 'Open AI Chatbot.'
    }
  ]


  return (
    <div className={`flex flex-col text-md min-w-12 bg-zinc-800 rounded-l`}>
      <div className={`flex flex-col text-white py-1 pr-1 space-y-1`}>
        {buttons.map(button => {
          return (
            <ButtonCard props={{
              button,
              preview
            }} />
          )
        })}
      </div>
    </div>
  );
}

const ButtonCard = ({ props }) => {
  const { component, setComponent } = useContext(BrowserContext)
  const { button: { name, toolTip, img, imgAlt }, preview } = props

  const isPdf = preview.split(".").slice(-1)[0]?.toLowerCase() === "pdf"

  if (name === "analyze" && (!isPdf || isProduction)) return;

  return (
    <Tooltip
      title={<p className="text-lg">{toolTip}</p>}
      placement="right"
      arrow
    >
      <div
        className={`${component === name ? "bg-blue-600" : "bg-zinc-800 hover:bg-zinc-600"
          } rounded mx-1 p-1 cursor-pointer`}
        onClick={() => setComponent(name)}
      >
        <img
          src={img}
          className="w-10"
          alt={imgAlt}
        />
      </div>
    </Tooltip>
  )
}
