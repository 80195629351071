import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { SearchContext } from "../../context/SearchContext";
import { ResultsContext } from "../../context/ResultsContext";
import { isMobile } from "react-device-detect";
import * as searchActions from "../../store/search";
import Results from "../Results";
import Browser from "../Browser";
import SearchBar from "./SearchBar";
import QueryPage from "../QueryPage";
import ResultInfo from "./ResultInfo";
import MobileResultInfo from "./MobileResultInfo";
import loadingIcon from "../../assets/icons/loading.png"

export default function Search() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const results = useSelector((state) => state.results.results);
    const user = useSelector(state => state.session.user)
    const resultsRef = useRef(null);
    const browserRef = useRef(null);
    const { view } = useParams()
    const [status, setStatus] = useState("");
    const [width, setWidth] = useState(window.innerWidth);
    const [isDragging, setIsDragging] = useState(false)
    const [selectResources, setSelectResources] = useState(false);
    const docExtensions = ["ppt", "doc", "docx", "pdf"];
    const {
        search,
        loadingResults,
        setIsRedditShared,
        setIsOnReddit,
        setLoading,
        setSearch
    } = useContext(SearchContext);

    const {
        preview,
        showResult,
        setStart,
        result,
    } = useContext(ResultsContext);

    const [initialWidths, setInitialWidths] = useState({
        results: 'full',
        browser: 'full',
        mouseX: null
    });

    const [initialHeights, setInitialHeights] = useState({
        results: 'full',
        browser: 'full',
        mouseY: null
    });

    useEffect(() => {
        if (isMobile && resultsRef.current && browserRef.current) {
            resultsRef.current.style.height = '50%';
            browserRef.current.style.height = '50%';
        }
        else if (!isMobile && resultsRef.current && browserRef.current) {
            resultsRef.current.style.width = '50%';
            browserRef.current.style.width = '50%';
        }
    }, [showResult]);

    useEffect(() => {
        if (view) {

            navigate(`/search/${view}`, { replace: true })
            setSearch(false)
        }
    }, [view])


    useEffect(() => {
        if (preview && !docExtensions.includes(preview.split(".").slice(-1)[0])) {
            dispatch(searchActions.fetchResult(result));
        }
        else if (preview && docExtensions.includes(preview.split(".").slice(-1)[0]) && (preview.includes('https://docs.google.com/gview?embedded=true&url=') || preview.includes('https://docs.google.com/viewerng'))) {
            dispatch(searchActions.fetchResult(result));
        }
    }, [preview, dispatch]);

    useEffect(() => {
        setIsRedditShared(false);
        setLoading(false);
        setIsOnReddit(false);
    }, [preview, setIsRedditShared, setLoading, setIsOnReddit]);

    useEffect(() => {
        if (results) {
            const lastResultIndex = Number(Object.keys(results).slice(-2, -1)[0]);
            setStart(lastResultIndex);
        }
    }, [results, setStart]);

    const handleMouseDown = (e) => {
        console.log('down')
        setIsDragging(true);

        // Store initial widths and mouse position
        if (isMobile) {
            setInitialHeights({
                results: resultsRef.current.offsetHeight,
                browser: browserRef.current.offsetHeight,
                mouseY: e.touches[0].clientY
            });
        } else {
            e.preventDefault();
            setInitialWidths({
                results: resultsRef.current.offsetWidth,
                browser: browserRef.current.offsetWidth,
                mouseX: e.clientX
            });
        }
    };

    const handleMouseMove = (e) => {
        if (isMobile) {

            if (!isDragging || !initialHeights.mouseY) return;
            const deltaY = e.touches[0].clientY - initialHeights.mouseY;
            const containerHeight = resultsRef.current.parentElement.offsetHeight;

            // Calculate new heights as percentages
            let newResultsHeight = ((initialHeights.results + deltaY) / containerHeight) * 100;
            let newBrowserHeight = ((initialHeights.browser - deltaY) / containerHeight) * 100;

            // Add minimum height constraints (e.g., 20%)
            if (newResultsHeight < 20) {
                newResultsHeight = 20;
                newBrowserHeight = 80;
            } else if (newBrowserHeight < 20) {
                newResultsHeight = 80;
                newBrowserHeight = 20;
            }

            // Apply new heights
            resultsRef.current.style.height = `${newResultsHeight}%`;
            browserRef.current.style.height = `${newBrowserHeight}%`;
        } else {

            if (!isDragging || !initialWidths.mouseX) return;
            const deltaX = e.clientX - initialWidths.mouseX;
            const containerWidth = resultsRef.current.parentElement.offsetWidth;

            // Calculate new widths as percentages
            let newResultsWidth = ((initialWidths.results + deltaX) / containerWidth) * 100;
            let newBrowserWidth = ((initialWidths.browser - deltaX) / containerWidth) * 100;

            // Add minimum width constraints (e.g., 20%)
            if (newResultsWidth < 20) {
                newResultsWidth = 20;
                newBrowserWidth = 80;
            } else if (newBrowserWidth < 20) {
                newResultsWidth = 80;
                newBrowserWidth = 20;
            }

            // Apply new widths
            resultsRef.current.style.width = `${newResultsWidth}%`;
            browserRef.current.style.width = `${newBrowserWidth}%`;
        }
    };

    const handleMouseUp = () => {
        console.log('up')
        setIsDragging(false);
    };

    // Add and remove event listeners
    useEffect(() => {
        if (isDragging) {
            if (isMobile) {
                console.log('Dragging: ', isDragging)

                window.addEventListener('touchmove', handleMouseMove);
                window.addEventListener('touchend', handleMouseUp);
            } else {

                window.addEventListener('mousemove', handleMouseMove);
                window.addEventListener('mouseup', handleMouseUp);
                document.body.style.cursor = 'ew-resize';
            }
        } else {
            if (isMobile) {
                window.removeEventListener('touchmove', handleMouseMove);
                window.removeEventListener('touchend', handleMouseUp);

            } else {
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
                document.body.style.cursor = 'default';
            }
        }

        return () => {
            window.removeEventListener('touchmove', handleMouseMove);
            window.removeEventListener('touchend', handleMouseUp);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            document.body.style.cursor = 'default';
        };
    }, [isDragging, initialWidths, initialHeights]);

    return (
        <div className={`flex flex-col w-full ${width < 640 ? "h-[95vh]" : "sm:h-[95vh] md:h-[95vh] lg:h-[95vh] xl:h-full"} items-end bg-gradient-to-b from-zinc-900 to-zinc-950 overflow-hidden z-10`} id="search-bar">
            <SearchBar status={status} setStatus={setStatus} />
            {results && search ? (
    <div className="flex flex-col h-full w-full overflow-hidden justify-center items-center">
                
                    {isMobile ? <MobileResultInfo /> : <ResultInfo />}
                    <div className={`flex w-full h-full overflow-y-hidden ${isMobile && showResult ? "flex flex-col" : "2xl:flex-row xl:flex-col lg:flex-col md:flex-col sm:flex-col"}`}>
                <Results
                    data={results}
                    selectResources={selectResources}
                    resultsRef={resultsRef}
                    resultsWidth={initialWidths.results}
                    resultsHeight={initialHeights.results}
                />

                {(showResult && (results || preview)) && (
                    <>
                        <div
                            className={`${isMobile ? `h-2 ${isDragging ? '!bg-blue-500' : ''}` : 'w-1 cursor-ew-resize hover:!bg-blue-500'} bg-white transition-colors`}
                            onMouseDown={handleMouseDown}
                            onTouchStart={handleMouseDown}
                        />
                        <Browser
                            browserRef={browserRef}
                            browserWidth={initialWidths.browser}
                            browserHeight={initialHeights.browser}
                        />
                    </>
                )}
                    </div>
                </div>
            ) : loadingResults ? (
                <div className="flex justify-center items-center w-full h-full">
                    <img src={loadingIcon} className="h-26 w-26 rounded-full animate-spin mb-4" alt="loading" />
                </div>
            ) : (
                <QueryPage />
            )}
        </div>
    );
}
