import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { SearchContext } from "../../context/SearchContext";
import { ResultsContext } from "../../context/ResultsContext";
import SaveResult from "./SaveResult";
import { isMobile } from "react-device-detect";
import MobileResultCard from "./MobileResultCard";
import newTab from "../../assets/icons/open_in_new.png";
import documentIcon from "../../assets/images/document.png";


export default function ResultCard({ data, rowKey, displayOnly, index, selectResources }) {
    const result = data[rowKey];
    const {
        setIsIndex,
        clickHistory
    } = useContext(SearchContext);
    const { setShowResult, setPreview, setResult, resourceSelection, setResourceSelection } = useContext(ResultsContext);

    const [showInfo, setShowInfo] = useState(false);
    const [saved, setSaved] = useState(result?.saved || false);
    const [lastSearchId, setLastSearchId] = useState(0);
    const [isSelected, setIsSelected] = useState(false);
    const lastSearch = useSelector((state) => state.search.recentQueries);
    const docExtensions = ["pdf", "doc", "docx", "ppt", "pptx"];

    useEffect(() => {
        if (lastSearch && Object.values(lastSearch)[0]) {
            setLastSearchId(Object.values(lastSearch)[0].id);
        }
    }, [lastSearch]);

    useEffect(() => {
        if (!resourceSelection.length) setIsSelected(false)
    }, [resourceSelection])

    const handleClick = () => {
        if (displayOnly) return;
        setIsIndex(false);
        const newResult = { ...data[rowKey] };
        newResult.queryId = lastSearchId;
        setShowInfo(!showInfo);
        setShowResult(true);
        setResult(newResult);
        setPreview(data[rowKey].link);
        clickHistory.setCurrentSelected(index);
        clickHistory.setVisitedResults([...clickHistory.visitedResults, index]);
        if (result.title.toLowerCase().includes("index of /")) setIsIndex(true);
        return;
    };

    const handleNewTab = () => {
        if (displayOnly) return;
        const link = data[rowKey].link;
        window.open(link, "_blank");
        return;
    };

    const handleresourceSelection = (e) => {
        setIsSelected(e.target.checked)

        if (e.target.checked) {
            setResourceSelection([...resourceSelection, result.id])
        }
        else {
            const newresourceSelection = resourceSelection.filter(id => id !== result.id)
            setResourceSelection([...newresourceSelection])
        }
    }

    const isDocument = () => docExtensions.includes(
        result.link
            .split(".")
            .slice(-1)[0]
    )

    if (isMobile) return <MobileResultCard data={data} rowKey={rowKey} selectResources={selectResources} />

    return (
        <div
            data-collapse-target="collapse"
            data-collapse="collapse"
            id="result"
            className={`
          ${!displayOnly ?
                    (clickHistory.currentSelected == index
                        ? "border-2 !border-green-400"
                        : (clickHistory.visitedResults?.includes(index) &&
                            clickHistory.currentSelected !== index
                            ? "border-2 border-white"
                            : "border-2 border-zinc-800")
                    ) : ''
                }
          group
          flex
          items-start
          rounded
          bg-zinc-800
          pr-4
          py-4
          transition-colors
          hover:bg-zinc-700
          w-full
          max-w-full
          cursor-pointer
          ${!displayOnly ? 'hover:border-2 hover:border-green-400' : ''}
        `}
        >
            {/* Left column with checkbox/save button and index indicator */}
            <div className="flex flex-col items-center gap-2 min-w-12">
                {selectResources ? (
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={handleresourceSelection}
                        className="h-6 w-6 cursor-pointer"
                    />
                ) : (
                    <SaveResult
                        result={result}
                        saved={saved}
                        setSaved={setSaved}
                        displayOnly={displayOnly}
                        className="w-3/4 cursor-pointer"
                    />
                )}

                {result.title?.toLowerCase().includes("index of /") && (
                    <div className="rounded bg-green-200 w-6 py-1 text-center text-xs">
                        Idx
                    </div>
                )}
            </div>

            {/* Main content area */}
            <div className="flex flex-col w-full min-w-0" onClick={handleClick}>
                {result && (
                    <div className="space-y-2 w-full min-w-0">
                        {/* Header row with title and icons */}
                        <div className="flex flex-col items-start justify-between gap-2 w-full">
                            <div className="flex items-center justify-between min-w-0 w-full flex-row">
                                <h3 className="font-bold text-xl text-slate-200 break-words underline">
                                    {result.title || ''}
                                </h3>
                                <div className="flex items-center gap-2 flex-shrink-0">
                                    {result?.link && isDocument() && (
                                        <img
                                            src={documentIcon}
                                            className={`${isMobile ? "w-6 h-6" : "w-8 h-8"}`}
                                            alt="document"
                                        />
                                    )}
                                    <a href={result.link} target="_blank">

                                        <img
                                            src={newTab}
                                            className={`h-${isMobile ? "6" : "8"} cursor-pointer`}
                                            onClick={handleNewTab}
                                            alt="new tab"
                                        />
                                    </a>
                                </div>

                            </div>
                            <p className="text-sm text-zinc-400 line-clamp-1 w-full">
                                {result.link}
                            </p>

                        </div>

                        {/* Snippet */}
                        <p className="text-zinc-300 text-lg break-words">
                            {result.snippet}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}
