import { useState, useContext } from "react";
import { SearchContext } from "../../context/SearchContext";
import plusIcon from "../../assets/images/plus-white.png"
import Tooltip from '@mui/material/Tooltip';

export default function Parameter({ index, param, text, selectedOperator, setSelectedOperator }) {
    const [input, setInput] = useState("");
    const { searchState } = useContext(SearchContext);
    const { query, setQuery, updateQuery } = searchState
    const { title, example, text: paramText } = param

    const handleSubmit = (e) => {
        e.preventDefault();

        if (input?.length) {
            const parsed = paramText + input;
            setQuery([...query, parsed]);
            updateQuery({ query: [...query, parsed] })
            setInput("");
        }
    };

    const handleInput = (e) => {
        const { value } = e.target
        setInput(value)
    }


    const AddToSearch = () => {
        return input?.length ? (
            <button
                type="submit"
                className="rounded-right hover:bg-green-800 h-full w-10"
            >
                <img
                    src={plusIcon}
                    className="w-full mr-0 self-end h-full"
                />
            </button>
        ) : <></>
    }

    return (
        <Tooltip title={<p className="text-xl">{title}</p>} arrow>

            <form
                className={`flex flex-row items-center h-10 p-1 w-full min-w-fit text-white rounded cursor-pointer ${selectedOperator === index ? "bg-blue-500 shadow-lg" : "hover:bg-zinc-700 hover:shadow-lg"}`}
                id="parameter"
                onSubmit={handleSubmit}
                onClick={() => setSelectedOperator(index)}
            >
                <div className="flex flex-row w-full items-center rounded h-full">
                    <div className="rounded-left flex-shrink-0 px-1 text-white h-fit !text-zinc-100 text-lg">
                            <p className="w-fit">{text}</p>
                    </div>
                    <div className="flex flex-row w-full items-center rounded h-full !bg-zinc-900">

                        <input
                            id=""
                            className={`pl-1 pr-0 outline-none w-full !bg-zinc-900 h-full text-white parameter-input min-w-fit ${!input.split(param.text)[1] ? "rounded-right" : ""
                                }`}
                            onChange={handleInput}
                            placeholder={example}
                            value={input}
                        />

                        <AddToSearch />
                    </div>

                </div>
            </form>
        </Tooltip>
    );
}
