import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useContext, useRef } from "react";
import { ResultsContext } from "../../context/ResultsContext";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Results from "../Results";
import Browser from "../Browser";
import * as searchActions from "../../store/search";
import OpenModalButton from "../OpenModalButton";
import NewGroupModal from "../ResourceGroups/NewGroupModal.js";
import ResultsPageFilters from "./ResultsPageFilters";

export default function ResultsPage() {
    const dispatch = useDispatch();
    const params = useParams();
    const resultsRef = useRef(null);
    const browserRef = useRef(null);
    const { preview, showResult, result, resourceSelection, setResourceSelection } = useContext(ResultsContext);

    const results = useSelector((state) => state.results.all);
    const [browseHistory, setBrowseHistory] = useState([]);
    const [selectResources, setSelectResources] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    // Store initial widths when starting to drag
    const [initialWidths, setInitialWidths] = useState({
        results: 'full',
        browser: 'full',
        mouseX: null
    });

    const [initialHeights, setInitialHeights] = useState({
        results: 'full',
        browser: 'full',
        mouseY: null
    });

    const docExtensions = ["pdf", "ppt", "doc", "docx"];

    // Initialize panel widths on component mount
    useEffect(() => {
        if (isMobile && resultsRef.current && browserRef.current) {
            resultsRef.current.style.height = '50%';
            browserRef.current.style.height = '50%';
        }
        else if (!isMobile && resultsRef.current && browserRef.current) {
            resultsRef.current.style.width = '50%';
            browserRef.current.style.width = '50%';
        }
    }, [showResult]);

    useEffect(() => {
        const { group } = params;
        if (group === 'new') setSelectResources(true);
        else setSelectResources(false);
    }, [params]);

    useEffect(() => {
        if (preview && !docExtensions.includes(preview.split(".").slice(-1)[0])) {
            dispatch(searchActions.fetchResult(result));
            if (!browseHistory.length) {
                setBrowseHistory([preview]);
            }
        }
    }, [preview, dispatch]);

    const cancelresourceSelection = () => {
        setSelectResources(false);
        setResourceSelection([]);
    };

    const handleMouseDown = (e) => {
        console.log('down')
        setIsDragging(true);

        // Store initial widths and mouse position
        if (isMobile) {
            setInitialHeights({
                results: resultsRef.current.offsetHeight,
                browser: browserRef.current.offsetHeight,
                mouseY: e.touches[0].clientY
            });
        } else {
            e.preventDefault();
            setInitialWidths({
                results: resultsRef.current.offsetWidth,
                browser: browserRef.current.offsetWidth,
                mouseX: e.clientX
            });
        }
    };

    const handleMouseMove = (e) => {
        if (isMobile) {

            if (!isDragging || !initialHeights.mouseY) return;
            const deltaY = e.touches[0].clientY - initialHeights.mouseY;
            const containerHeight = resultsRef.current.parentElement.offsetHeight;

            // Calculate new heights as percentages
            let newResultsHeight = ((initialHeights.results + deltaY) / containerHeight) * 100;
            let newBrowserHeight = ((initialHeights.browser - deltaY) / containerHeight) * 100;

            // Add minimum height constraints (e.g., 20%)
            if (newResultsHeight < 20) {
                newResultsHeight = 20;
                newBrowserHeight = 80;
            } else if (newBrowserHeight < 20) {
                newResultsHeight = 80;
                newBrowserHeight = 20;
            }

            // Apply new heights
            resultsRef.current.style.height = `${newResultsHeight}%`;
            browserRef.current.style.height = `${newBrowserHeight}%`;
        } else {

            if (!isDragging || !initialWidths.mouseX) return;
            const deltaX = e.clientX - initialWidths.mouseX;
            const containerWidth = resultsRef.current.parentElement.offsetWidth;

            // Calculate new widths as percentages
            let newResultsWidth = ((initialWidths.results + deltaX) / containerWidth) * 100;
            let newBrowserWidth = ((initialWidths.browser - deltaX) / containerWidth) * 100;

            // Add minimum width constraints (e.g., 20%)
            if (newResultsWidth < 20) {
                newResultsWidth = 20;
                newBrowserWidth = 80;
            } else if (newBrowserWidth < 20) {
                newResultsWidth = 80;
                newBrowserWidth = 20;
            }

            // Apply new widths
            resultsRef.current.style.width = `${newResultsWidth}%`;
            browserRef.current.style.width = `${newBrowserWidth}%`;
        }
    };

    const handleMouseUp = () => {
        console.log('up')
        setIsDragging(false);
    };

    // Add and remove event listeners
    useEffect(() => {
        if (isDragging) {
            if (isMobile) {
                console.log('Dragging: ', isDragging)

                window.addEventListener('touchmove', handleMouseMove);
                window.addEventListener('touchend', handleMouseUp);
            } else {

                window.addEventListener('mousemove', handleMouseMove);
                window.addEventListener('mouseup', handleMouseUp);
                document.body.style.cursor = 'ew-resize';
            }
        } else {
            if (isMobile) {
                window.removeEventListener('touchmove', handleMouseMove);
                window.removeEventListener('touchend', handleMouseUp);

            } else {
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
                document.body.style.cursor = 'default';
            }
        }

        return () => {
            window.removeEventListener('touchmove', handleMouseMove);
            window.removeEventListener('touchend', handleMouseUp);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            document.body.style.cursor = 'default';
        };
    }, [isDragging, initialWidths, initialHeights]);

    return (
        <div className={`flex flex-col ${preview && !isMobile ? "items-start" : "items-center"} w-full h-full bg-zinc-900`}>
            <div className={`flex items-center justify-center pt-2 flex-col ${isMobile ? "w-full" : "3xl:w-1/2 2xl:w-1/2 xl:w-full lg:w-full md:w-full"}`}>
                <ResultsPageFilters />
                {selectResources && (
                    <div className="w-full flex flex-row text-white items-center space-x-2 px-2">
                        <OpenModalButton
                            buttonText="Create Group"
                            modalComponent={<NewGroupModal setSelectResources={setSelectResources} />}
                            className={`h-10 text-white flex items-center ${resourceSelection.length ? 'bg-blue-700' : 'bg-zinc-500 !text-zinc-800'} rounded px-2`}
                        />
                        <h2 onClick={cancelresourceSelection} className="cursor-pointer">Cancel</h2>
                    </div>
                )}
            </div>
            <div className={`flex w-full h-full overflow-y-hidden ${isMobile && showResult ? "flex flex-col" : "2xl:flex-row xl:flex-col lg:flex-col md:flex-col sm:flex-col"}`}>
                <Results
                    data={results}
                    selectResources={selectResources}
                    resultsRef={resultsRef}
                    resultsWidth={initialWidths.results}
                    resultsHeight={initialHeights.results}
                />

                {(showResult && (results || preview)) && (
                    <>
                        <div
                            className={`${isMobile ? `h-2 ${isDragging ? '!bg-blue-500' : ''}` : 'w-1 cursor-ew-resize hover:!bg-blue-500'} bg-white transition-colors`}
                            onMouseDown={handleMouseDown}
                            onTouchStart={handleMouseDown}
                        />
                        <Browser
                            browserRef={browserRef}
                            browserWidth={initialWidths.browser}
                            browserHeight={initialHeights.browser}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
