import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { SearchContext } from "../../../context/SearchContext";
import { bingSettings } from "./BingSettings/bingSettings";
import { googleSettings } from "./GoogleSettings/googleSettings";
import * as searchActions from "../../../store/search";
import Parameter from "../Parameter.js";
import QueryParam from "../QueryParam.js";
import MobileSearchBarForm from "./MobileSearchBarForm.js";
import saveIcon from "../../../assets/icons/save.png"
const settings = { Google: googleSettings, Bing: bingSettings };

export default function MobileSearchBar({ setStatus, status }) {
    const [selectedOperator, setSelectedOperator] = useState(null);
    const {
        showOptions,
        searchState
    } = useContext(SearchContext);

    const dispatch = useDispatch();


    const saveQuery = () => {
        const options = {
            q: searchState.query.join(";"),
            hl: searchState.language,
            engine: searchState.engine.toLocaleLowerCase(),
            start: 0,
            string: searchState.string,
        };
        if (searchState.engine === "Bing") {
            options.location = searchState.country;
        } else {
            options.cr = searchState.country;
        }
        dispatch(searchActions.saveQuery(options));
    };

    const clearQuery = () => searchState.setQuery([])


    return (
        <div
            className={`w-full flex flex-col font-bold rounded transition-all duration-300 ease-in-out items-center justify-center px-3`}
            id="search-bar-inner"
            data-collapse="collapse"
        >
            <MobileSearchBarForm setStatus={setStatus} status={status} />
            <QueryActions props={{
                searchState,
                showOptions,
                saveQuery,
                clearQuery
            }} />
            <QueryParameters props={{
                showOptions,
                searchState,
                selectedOperator,
                setSelectedOperator
            }} />
        </div>
    );
}

const QueryActions = ({ props }) => {
    const { searchState, showOptions, saveQuery, clearQuery } = props
    return (
        searchState?.query?.length && showOptions ? (
            <div className="flex flex-row align-items-center justify-end w-full">
                <img
                    className="h-8 cursor-pointer px-1"
                    src={saveIcon}
                    onClick={saveQuery}
                    alt="save query"
                />
                <p
                    className={`px-1 text-white rounded h-8 flex align-items-center hover:text-slate-900 cursor-pointer`}
                    onClick={clearQuery}
                >
                    Clear
                </p>
            </div>
        ) : (
            <></>
        )
    )
}

const SelectLanguage = ({ props }) => {
    const { searchState } = props
    return (
        (searchState.engine === "Google" || searchState.engine === "Bing") ? (
            <div className="p-2">
                <select
                    // id="normalize"
                    className="pl-2 cursor-pointer bg-zinc-900 text-white py-1 rounded"
                    onChange={(e) => {
                        searchState.setLanguage(
                            settings[searchState.engine].languages[
                            e.target.value
                            ]
                        )
                        searchState.updateQuery({ hl: settings[searchState.engine].languages[e.target.value] })
                    }}
                >
                    <option
                        selected={searchState.language === ""}
                        disabled
                    >
                        Language
                    </option>

                    {Object.keys(
                        settings[searchState.engine].languages
                    ).map((name) => (
                        <option
                            selected={
                                settings[searchState.engine].languages[
                                name
                                ] === searchState.language
                            }
                            value={name}
                        >
                            {name}
                        </option>
                    ))}
                </select>
            </div>
        ) : <></>
    )
}

const SelectCountry = ({ props }) => {
    const { searchState } = props

    const handleSelectedCountry = (e) => {
        const newCountry = searchState.engine === "Google" ? { cr: settings[searchState.engine].countries[e.target.value] } : { location: settings[searchState.engine].countries[e.target.value] }
        searchState.updateQuery(newCountry)
        console.log(searchState.currentSearchStatus)
        searchState.setCountry(settings[searchState.engine].countries[e.target.value])
    }

    return (
        <div className="p-2">
            <select
                // id="normalize"
                className="pl-2 cursor-pointer text-white bg-zinc-900 py-1 rounded w-full"
                onChange={handleSelectedCountry}
            >
                <option
                    selected={searchState.country === ""}
                    disabled
                    className=""
                >
                    Country
                </option>

                {Object.keys(
                    settings[searchState.engine].countries
                ).map((name) => (
                    <option
                        value={name}
                        selected={
                            settings[searchState.engine].countries[
                            name
                            ] === searchState.country
                        }
                    >
                        {name}
                    </option>
                ))}
            </select>
        </div>
    )
}

const CurrentParameters = ({ props }) => {
    const { searchState } = props
    const parseParameter = (param) => {
        const operator = param.split(":")[0]
        const value = param.split(":")[1]?.split('"').join("") || ""
        return [operator, value]
    }
    return (
        searchState?.query?.length ? (
            <div className="flex flex-wrap p-1 w-full">
                {searchState.query.map((parameter, index) => {
                    if (parameter.includes(":")) {
                        const [param, value] = parseParameter(parameter)
                        return (
                            <QueryParam
                                key={`query-param-${index}`}
                                param={param}
                                value={value}
                                index={index}
                            />
                        );
                    }
                    return <></>
                })}
            </div>
        ) : (
            <></>
        )
    )
}

const ParameterOptions = ({ props }) => {
    const { searchState, selectedOperator, setSelectedOperator } = props
    const operators = Object.keys(settings[searchState.engine].operators)
    return (
        <div className={`w-full`}>
            {operators.map(
                (param, index) => (
                    <Parameter
                        index={index}
                        text={param}
                        param={
                            settings[searchState.engine].operators[param]
                        }
                        selectedOperator={selectedOperator}
                        setSelectedOperator={setSelectedOperator}
                    />
                )
            )}
        </div>
    )
}

const QueryParameters = ({ props }) => {
    const { showOptions, searchState, selectedOperator, setSelectedOperator } = props
    return (
        showOptions && (
            <div className="flex flex-col w-full">
                <CurrentParameters props={{
                    searchState
                }} />
                <div className={`flex flex-col bg-zinc-800 rounded w-full my-3`}>
                    <ParameterOptions props={{
                        searchState,
                        selectedOperator,
                        setSelectedOperator
                    }}/>
                    <div
                        className={`w-full h-full text-zinc-800`}
                    >
                        <SelectLanguage props={{
                            searchState
                        }} />
                        <SelectCountry props={{
                            searchState
                        }} />
                    </div>
                </div>
            </div>
        )
    )
}
