import { useContext, useEffect, useState } from "react";
import { ResultsContext } from "../../context/ResultsContext";
import { SearchContext } from "../../context/SearchContext";
import { BrowserContext } from "../../context/BroswerContext";
import BrowserHeader from "./BrowserHeader";
import { isMobile } from "react-device-detect";

export default function Browser({browserRef, browserWidth, browserHeight}) {
    const { preview, setPreview } = useContext(ResultsContext);
    const { searchState } = useContext(SearchContext);
    const {
        component,
        setComponent,
        displayUrl,
        setDisplayUrl,
        components
    } = useContext(BrowserContext)

    const docExtensions = ["ppt", "doc", "docx", "pdf"];

    useEffect(() => {
        if (
            preview &&
            docExtensions.includes(preview.split(".").slice(-1)[0]) &&
            // !preview.includes("https") &&
            !preview.includes("https://docs.google.com/viewerng") &&
            !preview.includes(
                "https://docs.google.com/gview?embedded=true&url="
            )
        ) {
            setPreview(
                `https://docs.google.com/gview?embedded=true&url=${preview}`
            );
            setDisplayUrl(preview);
            searchState.updateQuery({
                preview: preview
            })
        }
    }, [preview]);

    useEffect(() => {
        setComponent("browser");
        setDisplayUrl(preview);
    }, [preview]);

    const showComponent = (componentName, url) => {
        const ComponentToDisplay = components[componentName];
        return <ComponentToDisplay url={url} />;
    };

    return (
        <div
            className={`flex flex-row w-${browserWidth} h-${browserHeight} bg-zinc-500 ${isMobile ? "" : "ml-2"} p-1 rounded-lg overflow-hidden divide-x divide-zinc-500`}
            ref={browserRef}
        >
            <BrowserHeader preview={displayUrl} component={component} setComponent={setComponent} />
            {component ? showComponent(component, preview) : <></>}
        </div>
    );
}
