import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../../../context/SearchContext";
import { ResultsContext } from "../../../context/ResultsContext";
import * as searchActions from "../../../store/search";
import * as resultActions from "../../../store/result";
import clearText from "../../../assets/images/clear.png";
import searchIcon from "../../../assets/images/search.png";
import arrowForwardIcon from "../../../assets/images/arrow-forward-2.png"

export default function MobileSearchBarForm({ setStatus, status }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        setLoadingResults,
        showOptions,
        setShowOptions,
        queryLen,
        hasReachCharLimit,
        currCharCount,
        maxCharCount,
        searchState,
        clickHistory
    } = useContext(SearchContext);
    const { setPageNum, setTotalPages } = useContext(ResultsContext);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (searchState.query || searchState.string) {
            setLoadingResults(true);
            setStatus("initial");
            setShowOptions(false);
            let options = {};
            options = {
                q: searchState.query.join(";"),
                hl: searchState.language,
                engine: searchState.engine.toLocaleLowerCase(),
                start: 0,
                string: searchState.string,
            };
            if (searchState.engine === "Bing") {
                options.location = searchState.country;
            } else {
                options.cr = searchState.country;
            }
            searchState.updateQuery(options)
            dispatch(resultActions.search(options, (status = "initial"))).then(
                async (data) => {
                    navigate("/search");
                    dispatch(searchActions.getRecentQueries());
                    if (data?.results?.info?.totalPages) {
                        setTotalPages(data.results.info.totalPages);
                    }
                    setLoadingResults(false);
                }
            );
            searchState.setSearch(true);
            setStatus("next");
            clickHistory.setVisitedResults([]);
            clickHistory.setCurrentSelected(null);
            setPageNum(1);
        }
    };

    

    return (
        <form
            className={`w-full flex text-slate-200 items-center`}
            data-collapse-target="collapse"
            onSubmit={(e) => handleSubmit(e)}
        >
            <div className="flex items-center w-full h-[4vh] fit justify-content-between m-1">
                <div className={`flex flex-row h-full items-center w-full`}>
                    <img
                        src={arrowForwardIcon}
                        className={`h-[2.5vh] w-8 flex flex-row transition-all duration-300 ease-in-out z-20 ${showOptions ? "rotate-90" : ""
                            } cursor-pointer`}
                        onClick={() => setShowOptions(!showOptions)}
                        alt="Show search options."
                    />
                    <div
                        className={`flex flex-row h-full w-full jusitfy-center items-center`}
                    >
                        <div
                            className={`flex w-full h-full bg-zinc-800 rounded-full px-2 justify-between items-center`}
                        >
                            <SelectEngine props={{searchState}}/>
                            <SearchInput props={{searchState,  setShowOptions}} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row w-fit"></div>
            </div>
            {queryLen() && !hasReachCharLimit() ? (
                <button
                    className="flex justify-self-end rounded focus:outline-none mx-1"
                    type="submit"
                >
                    <img src={searchIcon} className="h-8" alt="Submit search." />
                </button>
            ) : currCharCount >= maxCharCount - 100 ? (
                <div
                    className={`${currCharCount >= maxCharCount
                        ? "!text-red-400"
                        : "!text-amber-400"
                        }  `}
                >
                    <p>
                        {currCharCount}/{maxCharCount}
                    </p>
                </div>
            ) : (
                <></>
            )}
        </form>
    )
}

const SelectEngine = ({props}) => {
    const {searchState} = props
        return (
            <div className="flex flex-row justify-center items-center h-full">
                <label className="flex items-center h-full m-0">
                    <select
                        onChange={(e) =>
                            searchState.setEngine(e.target.value)
                        }
                        className="rounded ml-1 cursor-pointer text-2xl focus:outline-none text-white bg-zinc-800 flex items-center justify-center"
                    >
                        <option
                            selected={"Google" === searchState.engine}
                            defaultValue
                        >
                            Google
                        </option>
                        {/* <option value={"Baidu"}>Baidu</option> */}
                        <option
                            value={"Bing"}
                            selected={"Bing" === searchState.engine}
                        >
                            Bing
                        </option>
                        {/* <option value={"Yandex"}>Yandex</option> */}
                    </select>
                </label>
            </div>
        )
    }

    const SearchInput = ({props}) => {
        const {searchState, setShowOptions} = props
        return (
            <>
                <input
                    placeholder="Search"
                    className={`px-1 bg-white/0 rounded w-full outline-none h-full text-white poppins-light text-lg`}
                    value={searchState.string}
                    onChange={(e) => {
                        searchState.updateQuery({ string: e.target.value })
                        searchState.setString(e.target.value)
                    }}
                    onClick={() => setShowOptions(true)}
                />
                <img
                    src={clearText}
                    className="h-full"
                    onClick={() => searchState.setString("")}
                    alt="Clear search bar text."
                />
            </>
        )
}
