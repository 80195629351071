import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import MobileSideBar from "./MobileSidebar";
import * as sessionActions from "../../store/session";
import RecentStats from "./RecentStats";
import { SearchContext } from "../../context/SearchContext";
import SideBarFooter from "./SideBarFooter";
// import SearchBar from "../SearchBar";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
// import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import plusIcon from "../../assets/images/plus.png"
const isProduction = process.env.NODE_ENV === "production";

export default function SideBar() {
  const { searchState } = useContext(SearchContext);
  const navigate = useNavigate();
  const user = useSelector((state) => state.session.user);
  const [feedbackMsg, setFeedbackMsg] = useState("");
  const [feedbackEmail, setFeedbackEmail] = useState("");
  const [sentFeedback, setSentFeedback] = useState(false);

  const handleSendFeedback = (e) => {
    e.preventDefault();
    if (!feedbackMsg.length) return
    sessionActions.sendFeedback({
      text: feedbackMsg,
      email: feedbackEmail,
    });
    setSentFeedback(true);
    setFeedbackMsg("");
  };

  const handleNewSearch = () => {
    searchState.setQuery([]);
    searchState.setString("");
    navigate("/search");
  };

  const navToNewGroup = () => navigate('/results/all/new')

  if (isMobile) return <MobileSideBar setSearch={searchState.setSearch} />;

  return (
    <div className="p-2 border-r border-1 border-zinc-600">
      <div className="h-full w-[350px] flex flex-row text-slate-100 bg-zinc-900 rounded-lg">
        <div className={`flex flex-row w-full`}>
          {user ? (
            <div
              className={`p-2 w-full flex flex-col justify-between h-full`}
            >
              <div className="flex flex-col w-full">
                <div className="w-full flex flex-row items-center justify-between h-fit"></div>
                <div className={`p-2 text-lg space-y-2`}>
                  <div
                    onClick={handleNewSearch}
                    className="flex flex-row text-2xl items-center cursor-pointer p-2 py-2 rounded bg-zinc-200 hover:bg-white text-black"
                  >
                    <img
                      src={plusIcon}
                      className="h-8"
                      alt="new search"
                    />
                    Search
                  </div>
                  {/* <div
                    onClick={navToNewGroup}
                    className="flex flex-row text-2xl items-center cursor-pointer p-2 py-2 rounded bg-zinc-200 hover:bg-white text-black"
                  >
                    <img
                      src={plusIcon}
                      className="h-8"
                      alt="new search"
                    />
                    <p className="w-full">

                      Resource Group
                    </p>
                    <p className="px-1 border-2 border-green-300 rounded text-green-300 text-[1rem] bg-green-600">New!</p>
                  </div> */}
                  {/* <div className="h-2" /> */}
                  {/* <div className="w-full p-2 text-2xl underline text-blue-600 poppins-regular-italic" onClick={() => navigate('/search/current')}>
                                        <h1>Current Search</h1>
                                    </div> */}
                  <div className="divide-y divide-zinc-600">

                    <RecentStats setSearch={searchState.setSearch} />
                  </div>
                </div>
              </div>
              {!sentFeedback ? (
                <form
                  onSubmit={(e) => handleSendFeedback(e)}
                  className="h-fit !text-zinc-200"
                >
                  <p className="text-2xl">Feedback:</p>
                  <div className="h-2" />
                  <div className="p-1">

                    <input
                      placeholder="Email (optional)"
                      onChange={(e) =>
                        setFeedbackEmail(e.target.value)
                      }
                      value={feedbackEmail}
                      className="bg-zinc-800 mb-2 rounded p-1"
                    />
                    <div className="flex flex-col justify-end">
                      <textarea
                        onChange={(e) =>
                          setFeedbackMsg(e.target.value)
                        }
                        placeholder={`Have questions or feedback?\nAny features you would like to see?\nHave you experienced bugs that were not fixed?`}
                        value={feedbackMsg}
                        className="w-full h-40 bg-zinc-800 rounded p-1 !border-1 !border-zinc-500"
                      />
                      <div className="h-2" />

                      <button
                        type="submit"
                        className="focus:outline-none rounded p-1 hover:bg-zinc-600 w-fit align-self-end"
                      >
                        Send Feedback
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <p>Thank you for your feedback.</p>
              )}
              <SideBarFooter />
            </div>
          ) : (
            <div className="w-[300px]"></div>
          )}
        </div>
      </div>
    </div>
  );
}

function NestedList() {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Nested List Items
        </ListSubheader>
      }
    >
      <ListItemButton>
        <ListItemIcon>
          {/* <SendIcon /> */}
        </ListItemIcon>
        <ListItemText primary="Sent mail" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          {/* <DraftsIcon /> */}
        </ListItemIcon>
        <ListItemText primary="Drafts" />
      </ListItemButton>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          {/* <InboxIcon /> */}
        </ListItemIcon>
        <ListItemText primary="Inbox" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Starred" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}
