import { useState } from "react";
import { isMobile } from "react-device-detect";
import MobileGuidePage from "./MobileGuidePage";
import guideInfo from "./guide-info.json";
import ResultCard from "../Results/ResultCard";

const Card = ({ operator, description, example, exampleData }) => {
    return (
        <div className="p-6 bg-zinc-700 rounded-lg shadow-md mb-6" id={operator.split('').filter(char => char !== ' ').join('').toLowerCase()}>
            <h1 className="text-3xl text-amber-500 mb-2">{operator}</h1>
            <p className="text-zinc-200 mb-4 text-xl">{description}</p>
            <div className="flex flex-col w-1/2">
                <p className="text-lg mb-1">Example:</p>
                <div className="text-zinc-200 bg-zinc-800 rounded p-3 mb-2">

                    <div className="flex flex-row w-full items-end rounded h-full">
                        <div className="rounded-left flex-shrink-0 text-white h-fit !text-zinc-100 text-lg">
                            <p>{operator}</p>
                        </div>
                        <p
                            className={`pl-2 pr-0 outline-none w-full !bg-zinc-800 h-fit text-white parameter-input poppins-bold`}
                        >{example}</p>
                    </div>
                </div>
                <div className="max-w-fit">

                    {Object.keys(exampleData).map((row) => {
                        return (
                            <ResultCard data={exampleData} rowKey={row} displayOnly={true} />
                        )
                    }
                    )}
                </div>

            </div>
        </div>
    );
};

export default function GuidePage() {
    const [engine, setEngine] = useState("google");
    const [selectedOperator, setSelectedOperator] = useState(0);


    if (isMobile) {
        return <MobileGuidePage />;
    }

    else return (
        <div className="h-full w-full bg-zinc-900 text-white p-8 pb-0 flex flex-row">
            <SelectOperator props={{selectedOperator, setSelectedOperator, engine}}/>
            <div className="flex flex-col w-full px-8 h-full">
                <SelectEngine props={{setEngine, engine}}/>
                <div className="w-fit overflow-y-scroll h-full">
                    <Introduction />

                    {Object.values(guideInfo[engine]).map((info, index) => (
                        (
                            <Card
                                // key={index}
                                operator={info.operator}
                                description={info.description}
                                example={info.example}
                                exampleData={info.exampleData}
                            />
                        )
                    ))}
                </div>
            </div>
        </div>
    );
};

const SelectOperator = ({props}) => {
    const linkToOperator = (info) => `#${info.operator.split('').filter(char => char !== ' ').join('').toLowerCase()}`
    const {selectedOperator, setSelectedOperator, engine} = props

    return (
        <div className="p-4 bg-zinc-800 rounded-lg shadow-md w-1/6 mb-8 h-full">
            <h2 className="text-2xl font-bold mb-4">Operators</h2>
            <div className="flex flex-col space-y-2 w-full min-w-fit">
                {Object.values(guideInfo[engine]).map((info, index) => (
                    <a
                        // key={index}
                        className={`p-2 rounded cursor-pointer w-full min-w-fit ${selectedOperator === index ? 'bg-amber-700' : 'bg-zinc-700 hover:bg-zinc-600'} hover:text-white hover:underline-none`}
                        onClick={() => setSelectedOperator(index)}
                        href={(info) => linkToOperator(info)}
                    >
                        <p className="text-2xl min-w-fit">{info.operator}</p>
                    </a>
                ))}
            </div>
        </div>
    )
}

const SelectEngine = ({props}) => {
    const {setEngine, engine} = props
    return (
        <div className="flex justify-start mb-8 text-2xl">
            <button
                className={`mx-2 px-4 py-2 rounded ${engine === 'google' ? 'bg-amber-700' : 'bg-zinc-700 hover:bg-zinc-600'} focus:outline-none`}
                onClick={() => setEngine('google')}
            >
                Google
            </button>
            <button
                className={`mx-2 px-4 py-2 rounded ${engine === 'bing' ? 'bg-amber-700' : 'bg-zinc-700 hover:bg-zinc-600'} focus:outline-none`}
                onClick={() => setEngine('bing')}
            >
                Bing
            </button>
        </div>
    )
}

const Introduction = () => {
    return (
        <div className="p-6 bg-zinc-700 rounded-lg shadow-md mb-6">
            <div className="w-1/2 mb-4">
                <h1 className="text-3xl mb-1 text-amber-500">What is this ?</h1>
                <p className="text-wrap text-xl">The basic idea of search operators is simple:
                    They're special commands you type into a search box to make your searches more precise. Instead of just typing regular words, you add these special symbols or words to tell the search engine exactly what you want to find or avoid.
                    It's like giving the search engine extra instructions to help it understand what you're really looking for.</p>
            </div>
            <div className="w-1/2">
                <h1 className="text-3xl mb-1 text-amber-500">Simplified</h1>
                <p className="text-wrap text-xl">
                    Our user-friendly interface makes search operators easy. Instead of typing commands, you select options, and we handle the rest—helping you refine searches quickly without needing to know the syntax.
                </p>
            </div>
        </div>
    )
}
