import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import * as sessionActions from "../../store/session";
import ToS from "../SignupFormPage/ToS";

export default function FinishSignup() {
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [errors, setErrors] = useState({});
    const [acceptedTOS, setAcceptedTOS] = useState(false);
    const navigate = useNavigate()
    const user = useSelector((state) => state.session.user);

    useEffect(() => {
        if (username.length && username.length < 6) {
            setErrors({
                ...errors,
                username: "Username must be 6 characters or more",
            });
        }
        if (
            !username.length ||
            (username.length &&
                (username.length > 6 || username.length === 6) &&
                errors.username)
        ) {
            const newErrors = { ...errors };
            delete newErrors.username;
            setErrors(newErrors);
        }
    }, [username]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!username || !acceptedTOS) {
            return
        }

        dispatch(sessionActions.signup({ username, finishSignup: true }))
            .then(async (data) => {
                if (data && data.user) {
                    navigate("/search");
                }
            })
    };
    if (!user?.tempUser) navigate('/search')






    return (
        <div className="w-full h-full flex items-center justify-center">
            <div
                className="w-full max-w-md p-8 space-y-8 bg-zinc-800 rounded-lg shadow-lg text-white"
            >
                <UsernameForm props={{setUsername, username, errors, handleSubmit, acceptedTOS, setAcceptedTOS}}/>
            </div>
        </div>
    );
}

const UsernameForm = ({props}) => {
    const {setUsername, username, errors, handleSubmit, acceptedTOS, setAcceptedTOS} = props
    return (
        <form
            onSubmit={(e) => handleSubmit(e)}
            className="w-full"
        >
            <div className="py-2 flex flex-col items-center w-full h-fit justify-center space-y-6">
                <Username props={{setUsername, username, errors}} />
                <ToS acceptedTOS={acceptedTOS} setAcceptedTOS={setAcceptedTOS} />
                <SubmitButton props={{username, acceptedTOS}}/>
            </div>
        </form>
    )
}

const Username = ({props}) => {
    const {setUsername, username, errors} = props

    const validUsernameStyle = () => errors.username ? "border-red-200 bg-red-100" : "border-gray-300"
    const handleUsername = (e) => setUsername(e.target.value)
    return (
        <div className="w-full">

            <h1 className="align-self-start">Username *</h1>
            <input
                required
                value={username}
                placeholder="Username"
                onChange={handleUsername}
                className={`my-1 p-1 rounded text-black w-full border-4 ${validUsernameStyle()} focus:outline-none`}
            />
            {errors.username ? (
                <p className="text-red-300 h-fit w-full text-center">
                    {errors.username}
                </p>
            ) : (
                <p className="h-6"></p>
            )}
        </div>
    )
}

const SubmitButton = ({props}) => {
    const {username, acceptedTOS} = props
    const disableSubmit = () => username.length <= 6 || !acceptedTOS
    const validForm = () => username.length >= 6 && acceptedTOS ? 'bg-blue-500' : 'bg-zinc-600 focus:bg-zinc-600'
    return (
        <div className="w-full">

            <button
                type="submit"
                className={`my-1 rounded ${validForm()} p-1 px-2 w-full hover:bg-slate-700 text-xl`}
                disabled={disableSubmit()}
            >
                Finish Signup
            </button>
        </div>
    )
}
