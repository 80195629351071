import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import * as sessionActions from "../../store/session";
import logo from "../../assets/images/searchdeck-favicon.png";
import profileImg from "../../assets/icons/profile.png";
import logoutIcon from '../../assets/images/logout.png'
import accountIcon from '../../assets/images/manage-account.png'

function Navigation() {
    const sessionUser = useSelector((state) => state.session.user);
    const [showMenu, setShowMenu] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const path = window.location.pathname

    const handleLogOut = (e) => {
        e.preventDefault();
        setShowMenu(false);
        dispatch(sessionActions.logout()).then(async () => {
            navigate("/");
        });
    };

    const handleNavigate = () => {
        if (sessionUser && sessionUser.id) {
            navigate("/search");
        } else {
            navigate("/");
        }
    };




    return (
        <div className="h-[5%] w-screen fixed flex flex flex-row items-center text-white bg-zinc-900 z-20 border-b border-1 border-zinc-600">

            <div className="w-full flex flex-row items-center justify-between px-4">

                <div className="flex flex-row items-center space-x-4">
                    <NavToHome props={{handleNavigate, logo}}/>
                    <NavToGuide props={{navigate}}/>
                </div>
                {sessionUser ? (
                    <UserNav props={{handleLogOut, setShowMenu, showMenu, navigate}} />
                ) : (
                    <SessionButtons props={{navigate, path}} />
                )}
            </div>
        </div>
    );
}
const SessionButtons = ({props}) => {
    const {path, navigate} = props
    return path !== '/login' ? <LoginButton props={{navigate}}/> : <SignupButton props={{navigate}}/>
}

const NavToHome = ({ props }) => {
    const {handleNavigate, logo} = props
    return (
        <div
            className="flex flex-row items-center cursor-pointer items-center "
            onClick={handleNavigate}
        >
            <img src={logo} className="flex h-10" />
            <h1 className="text-3xl p-2 poppins-light">SearchDeck</h1>
        </div>
    )
}

const NavToGuide = ({ props }) => {

    const {navigate} = props

    return (
        <div className="flex flex-row text-zinc-300 text-xl items-center h-fit">
            <p
                className="cursor-pointer"
                onClick={() => {
                    navigate('/guide')
                }}
            >
                Guide
            </p>
        </div>
    )
}

const NavToAccount = ({ props }) => {
    const { navigate} = props
    return (
        <div
            className="w-full h-10 rounded flex items-center hover:bg-zinc-600 p-1 cursor-pointer"
            onClick={() => navigate('/user/settings')}
        >
            <img src={accountIcon} className="h-7 mr-2" alt="account settings" />
            <p>Account</p>
        </div>
    )
}

const LogoutButton = ({ props }) => {
    const {handleLogOut, logoutIcon} = props
    return (
        <div
            className="w-full h-10 rounded flex items-center hover:bg-zinc-600 p-1 cursor-pointer"
            onClick={(e) => handleLogOut(e)}
        >
            <img src={logoutIcon} className="h-7 mr-2" alt="logout" />
            <p>Logout</p>
        </div>
    )
}

const UserNavMenu = ({ props }) => {
    const {handleLogOut, navigate} = props
    return (
        <div className="flex flex-col fixed h-fit bg-zinc-800 rounded w-32 mt-12 p-2 z-10 border-2 border-zinc-500">
            <NavToAccount props={{accountIcon, navigate}}/>
            <LogoutButton props={{handleLogOut, logoutIcon}}/>
        </div>
    )
}

const UserNav = ({ props }) => {
    const {showMenu, setShowMenu, handleLogOut, navigate} = props
    return (
        <div className="flex justify-items-center">
            <div
                className="flex flex-col items-end  cursor-pointer"
                onClick={() => setShowMenu(!showMenu)}
            >
                <div className="flex flex-row items-center space-x-2">
                    <div className="flex flex-row items-center justify-center border-2 border-green-400 bg-green-200 rounded p-1 px-2 text-green-600">
                        <p>Free</p>
                    </div>
                    <img
                        src={profileImg}
                        className="rounded-full h-10"
                        alt="profile"
                    />
                </div>
                {showMenu ? (
                    <UserNavMenu props={{
                        handleLogOut,
                        navigate
                    }}/>
                ) : (
                    <></>
                )}
            </div>
        </div >
    )
}

const LoginButton = ({ props }) => {
    const {navigate} = props
    return (
        <div className="flex flex-row items-center justify-center">
            <Button
                variant="contained"
                onClick={() => navigate("/login")}
                className="focus:outline-none"
            >
                Login
            </Button>
        </div>
    )
}

const SignupButton = ({ props }) => {
    const {navigate} = props
    return (
        <div className="flex flex-row items-center justify-center">
            <Button
                variant="contained"
                onClick={() => navigate("/signup")}
                className="focus:outline-none"
            >
                Signup
            </Button>
        </div>
    )
}

export default Navigation;
