import {useState, useEffect, createContext} from 'react'

export const SearchHistoryContext = createContext()

export const SearchHistoryProvider = ({children}) => {
    const [viewAll, setViewAll] = useState(true);
    const [filter, setFilter] = useState("");
    const [limit, setLimit] = useState(25);
    const [engineFilter, setEngineFilter] = useState("");
    const [sortedQueries, setSortedQueries] = useState({});
    const [error, setError] = useState("");


    useEffect(() => {
        setError("")
        setSortedQueries({})
    }, [viewAll])

    return(
        <SearchHistoryContext.Provider
            value={{
                viewAll,
                setViewAll,
                filter,
                setFilter,
                limit,
                setLimit,
                engineFilter,
                setEngineFilter,
                sortedQueries,
                setSortedQueries,
                error,
                setError
            }}
        >
            {children}
        </SearchHistoryContext.Provider>
    )
}
