import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "../../context/Snackbar";
import { SearchContext } from "../../context/SearchContext";
import * as resultActions from "../../store/result";
import bookarkFill from "../../assets/icons/bookmark_FILL.png"
import bookmark from "../../assets/icons/bookmark.png"

export default function SaveResult({ result, saved, setSaved, className, displayOnly }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.session.user)
  const { search } = useContext(SearchContext)
  const [savedId, setSavedId] = useState(result?.id || null)
  const [lastSearchId, setLastSearchId] = useState(0)

  const { openSnackbar } = useSnackbar()

  const saveResult = () => {
    if (displayOnly || !user) return
    const newResult = {
      title: result.title,
      snippet: result.snippet,
      link: result.link,
      queryId: lastSearchId,
    };

    resultActions.postSavedResult(newResult, !search ? result.id : null)
      .then(async data => {
        const { id } = data
        setSavedId(id)
        openSnackbar('success', 'Result Saved', () => {
          deleteResult(savedId)
        })
      })
      .catch(() => {
        openSnackbar('error')
      })
    setSaved(true);
  };


  const isSavedResult = () => (saved || result.saved)

  const deleteResult = () => {
    if (displayOnly || !user) return
    dispatch(resultActions.deleteResult(savedId))
      .then(() => {
        openSnackbar('success', 'Result Un-saved')
      })
      .catch(() => {
        openSnackbar('error')
      })
  }
  return (
    <img
      onClick={isSavedResult() ? deleteResult : saveResult}
      src={isSavedResult() ? bookarkFill : bookmark}
      className={className}
    />
  )
}
